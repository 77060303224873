import React, { useState, useEffect } from 'react';
import './Stock.css';
import { StockForm, StockItemsList } from './StockComponents';
import { BarcodeScanner } from './BarcodeScanner';

export const Stock = () => {
  const [activeTab, setActiveTab] = useState('liste-stock');
  const [stockItems, setStockItems] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [showScanner, setShowScanner] = useState(false);
  const [scanTarget, setScanTarget] = useState(null); // 'search', 'add', 'update'
  const [scanCooldown, setScanCooldown] = useState(false);

  // Charger les articles du stock au chargement du composant
  useEffect(() => {
    const storedItems = JSON.parse(localStorage.getItem('stock_items') || '[]');
    setStockItems(storedItems);
    
    // Extraire les catégories uniques
    const uniqueCategories = [...new Set(storedItems.map(item => item.categorie))].filter(Boolean);
    setCategories(uniqueCategories);
    
    // Charger les sous-catégories
    const storedSubCategories = JSON.parse(localStorage.getItem('sous_categories') || '{}');
    setSubCategories(storedSubCategories);
  }, []);
  
  // Mettre à jour les sous-catégories lorsque la catégorie sélectionnée change
  useEffect(() => {
    if (selectedCategory) {
      const categorySubCategories = subCategories[selectedCategory] || [];
      setSelectedSubCategory(''); // Réinitialiser la sous-catégorie sélectionnée
    }
  }, [selectedCategory, subCategories]);

  // Fonction pour gérer la détection d'un code-barres
  const handleBarcodeDetected = (barcode) => {
    console.log("Code-barres détecté:", barcode);
    
    // Si nous sommes en période de refroidissement, ignorer cette détection
    if (scanCooldown) {
      return;
    }

    // Activer le refroidissement pour éviter les détections multiples
    setScanCooldown(true);
    setTimeout(() => setScanCooldown(false), 3000); // 3 secondes de refroidissement
    
    // Rechercher si le produit existe déjà en stock
    const existingItem = stockItems.find(item => item.reference === barcode);
    
    if (scanTarget === 'search') {
      // Mode recherche: filtrer pour afficher seulement l'article correspondant
      setSearchTerm(barcode);
      setShowScanner(false);
    } 
    else if (scanTarget === 'add') {
      // Mode ajout: préremplir le formulaire avec la référence du code-barres
      if (existingItem) {
        // Si l'article existe déjà, proposer de l'éditer
        setEditItem(existingItem);
        setActiveTab('nouveau-stock');
      } else {
        // Sinon, créer un nouvel article avec la référence préremplie
        const defaultFormData = {
          nom: '',
          reference: barcode,
          categorie: '',
          sousCategorie: '',
          nouvelleSousCategorie: '',
          quantite: 0,
          quantiteMin: 0,
          prixAchat: '',
          description: '',
          fournisseur: '',
          emplacement: '',
          image: ''
        };
        setEditItem(defaultFormData);
        setActiveTab('nouveau-stock');
      }
      setShowScanner(false);
    }
    else if (scanTarget === 'update') {
      // Mode mise à jour: ouvrir le modal d'ajustement de stock
      if (existingItem) {
        // Simuler un clic sur le bouton "Ajuster Stock" de cet article
        setShowScanner(false);
        // Nous allons utiliser la fonction qui sera passée à StockItemsList
        openUpdateStockModal(existingItem);
      } else {
        alert(`Aucun article avec la référence ${barcode} n'a été trouvé dans le stock.`);
        setShowScanner(false);
      }
    }
  };

  // Fonction pour ouvrir le modal d'ajustement de stock (sera passée à StockItemsList)
  const openUpdateStockModal = (item) => {
    // Cette fonction est un proxy qui sera remplacée par la fonction réelle dans StockItemsList
    // Dans un contexte réel, vous utiliseriez un état et un modal dans ce composant
    // ou vous trouveriez un moyen de déclencher la fonction dans StockItemsList
    console.log("Demande d'ouverture du modal d'ajustement pour l'article:", item);
    
    // Pour cet exemple, nous allons juste préparer l'item pour l'édition
    setEditItem(item);
    setActiveTab('nouveau-stock');
  };
  
  // Gestion des sous-catégories
  const handleSubCategoryChange = (subCategory) => {
    setSelectedSubCategory(subCategory);
  };

  // Fonction pour ajouter un nouvel article
  const addStockItem = (itemData) => {
    const newItem = {
      ...itemData,
      id: Date.now()
    };
    
    const updatedItems = [...stockItems, newItem];
    setStockItems(updatedItems);
    localStorage.setItem('stock_items', JSON.stringify(updatedItems));
    
    // Mettre à jour les catégories si nécessaire
    const newCategory = itemData.categorie;
    if (newCategory && !categories.includes(newCategory)) {
      const updatedCategories = [...categories, newCategory];
      setCategories(updatedCategories);
    }
    
    // Mettre à jour les sous-catégories si nécessaire
    if (itemData.sousCategorie && itemData.categorie) {
      const updatedSubCategories = {...subCategories};
      if (!updatedSubCategories[itemData.categorie]) {
        updatedSubCategories[itemData.categorie] = [];
      }
      
      if (!updatedSubCategories[itemData.categorie].includes(itemData.sousCategorie)) {
        updatedSubCategories[itemData.categorie].push(itemData.sousCategorie);
        setSubCategories(updatedSubCategories);
        localStorage.setItem('sous_categories', JSON.stringify(updatedSubCategories));
      }
    }
    
    // Réinitialiser l'état d'édition
    setEditItem(null);
    
    // Passer à l'onglet liste après l'ajout
    setActiveTab('liste-stock');
    
    // Afficher un message de confirmation
    alert('Article ajouté avec succès !');
  };

  // Fonction pour modifier un article existant
  const updateStockItem = (itemData) => {
    // Trouver l'article original avant les modifications
    const originalItem = stockItems.find(item => item.id === itemData.id);
    
    // Créer une entrée d'historique pour la modification
    const movement = {
      date: new Date().toISOString(),
      quantityBefore: originalItem.quantite,
      quantityAfter: itemData.quantite,
      difference: itemData.quantite - originalItem.quantite,
      notes: `Modification de l'article - ${itemData.nom !== originalItem.nom ? 'Nom modifié, ' : ''}${itemData.reference !== originalItem.reference ? 'Référence modifiée, ' : ''}${itemData.categorie !== originalItem.categorie ? 'Catégorie modifiée, ' : ''}${itemData.sousCategorie !== originalItem.sousCategorie ? 'Sous-catégorie modifiée, ' : ''}${itemData.quantiteMin !== originalItem.quantiteMin ? 'Seuil d\'alerte modifié, ' : ''}${itemData.prixAchat !== originalItem.prixAchat ? 'Prix modifié, ' : ''}${itemData.description !== originalItem.description ? 'Description modifiée, ' : ''}${itemData.fournisseur !== originalItem.fournisseur ? 'Fournisseur modifié, ' : ''}${itemData.emplacement !== originalItem.emplacement ? 'Emplacement modifié, ' : ''}`
    };
    
    // Mettre à jour l'historique de l'article
    const updatedItemData = {
      ...itemData,
      historique: [...(originalItem.historique || []), movement]
    };
    
    const updatedItems = stockItems.map(item => 
      item.id === updatedItemData.id ? updatedItemData : item
    );
    
    setStockItems(updatedItems);
    localStorage.setItem('stock_items', JSON.stringify(updatedItems));
    
    // Mettre à jour les catégories si nécessaire
    const newCategory = itemData.categorie;
    if (newCategory && !categories.includes(newCategory)) {
      const updatedCategories = [...categories, newCategory];
      setCategories(updatedCategories);
    }
    
    // Mettre à jour les sous-catégories si nécessaire
    if (itemData.sousCategorie && itemData.categorie) {
      const updatedSubCategories = {...subCategories};
      if (!updatedSubCategories[itemData.categorie]) {
        updatedSubCategories[itemData.categorie] = [];
      }
      
      if (!updatedSubCategories[itemData.categorie].includes(itemData.sousCategorie)) {
        updatedSubCategories[itemData.categorie].push(itemData.sousCategorie);
        setSubCategories(updatedSubCategories);
        localStorage.setItem('sous_categories', JSON.stringify(updatedSubCategories));
      }
    }
    
    // Réinitialiser l'état d'édition
    setEditItem(null);
    
    // Passer à l'onglet liste après la modification
    setActiveTab('liste-stock');
    
    // Afficher un message de confirmation
    alert('Article mis à jour avec succès !');
  };

  // Fonction pour supprimer un article
  const deleteStockItem = (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet article ?')) {
      // Obtenir les informations de l'article supprimé pour l'historique global
      const itemToDelete = stockItems.find(item => item.id === id);
      
      // Créer un nouvel enregistrement d'historique global (si nécessaire)
      const globalHistory = JSON.parse(localStorage.getItem('stock_global_history') || '[]');
      globalHistory.push({
        date: new Date().toISOString(),
        action: 'delete',
        itemId: id,
        itemName: itemToDelete.nom,
        itemReference: itemToDelete.reference,
        quantity: itemToDelete.quantite,
        notes: `Article supprimé - ${itemToDelete.nom} (${itemToDelete.reference})`
      });
      localStorage.setItem('stock_global_history', JSON.stringify(globalHistory));
      
      // Filtrer l'article supprimé
      const updatedItems = stockItems.filter(item => item.id !== id);
      
      setStockItems(updatedItems);
      localStorage.setItem('stock_items', JSON.stringify(updatedItems));
      
      // Mettre à jour les catégories (retirer celles qui ne sont plus utilisées)
      const remainingCategories = [...new Set(updatedItems.map(item => item.categorie))].filter(Boolean);
      setCategories(remainingCategories);
      
      // Afficher un message de confirmation
      alert('Article supprimé avec succès !');
    }
  };

  // Fonction pour éditer un article
  const editStockItemHandler = (id) => {
    const itemToEdit = stockItems.find(item => item.id === id);
    if (itemToEdit) {
      setEditItem(itemToEdit);
      setActiveTab('nouveau-stock');
    }
  };

  // Fonction pour mettre à jour le niveau de stock
  const updateStockLevel = (id, newQuantity, notes = '') => {
    const updatedItems = stockItems.map(item => {
      if (item.id === id) {
        // Ajouter un mouvement de stock à l'historique
        const currentQuantity = item.quantite || 0;
        const movement = {
          date: new Date().toISOString(),
          quantityBefore: currentQuantity,
          quantityAfter: newQuantity,
          difference: newQuantity - currentQuantity,
          notes: notes || `Ajustement manuel de ${currentQuantity} à ${newQuantity}`
        };
        
        // Créer ou mettre à jour l'historique
        const stockHistory = item.historique || [];
        
        return {
          ...item,
          quantite: newQuantity,
          historique: [...stockHistory, movement]
        };
      }
      return item;
    });
    
    setStockItems(updatedItems);
    localStorage.setItem('stock_items', JSON.stringify(updatedItems));
    
    alert('Niveau de stock mis à jour avec succès !');
  };

  // Filtrer les articles selon le terme de recherche, la catégorie et sous-catégorie sélectionnées
  const filteredItems = stockItems.filter(item => {
    const term = searchTerm.toLowerCase();
    const categoryMatch = selectedCategory ? item.categorie === selectedCategory : true;
    const subCategoryMatch = selectedSubCategory ? item.sousCategorie === selectedSubCategory : true;
    
    return (
      categoryMatch && 
      subCategoryMatch &&
      ((item.nom && item.nom.toLowerCase().includes(term)) ||
       (item.reference && item.reference.toLowerCase().includes(term)) ||
       (item.description && item.description.toLowerCase().includes(term)))
    );
  });

  // Calculer le nombre d'articles en rupture de stock
  const outOfStockCount = stockItems.filter(item => item.quantite <= 0).length;
  const lowStockCount = stockItems.filter(item => item.quantite > 0 && item.quantite <= item.quantiteMin).length;

  return (
    <section className="content-section active">
      <h1>Gestion des Stocks</h1>
      
      {/* Indicateurs de stock */}
      {(outOfStockCount > 0 || lowStockCount > 0) && (
        <div className="stock-alerts">
          {outOfStockCount > 0 && (
            <div className="stock-alert-indicator">
              <span className="stock-alert-badge">{outOfStockCount}</span>
              <span>Articles en rupture de stock</span>
            </div>
          )}
          {lowStockCount > 0 && (
            <div className="stock-alert-indicator warning">
              <span className="stock-alert-badge warning">{lowStockCount}</span>
              <span>Articles sous le seuil minimal</span>
            </div>
          )}
        </div>
      )}
      
      {/* Onglets de navigation secondaire */}
      <div className="tabs-container">
        <button 
          className={`tab-btn ${activeTab === 'liste-stock' ? 'active' : ''}`}
          onClick={() => setActiveTab('liste-stock')}
        >
          Liste des Articles
        </button>
        <button 
          className={`tab-btn ${activeTab === 'nouveau-stock' ? 'active' : ''}`}
          onClick={() => {
            setEditItem(null); // Réinitialiser pour un nouvel article
            setActiveTab('nouveau-stock');
          }}
        >
          Nouvel Article
        </button>
      </div>
      
      {/* Contenu des onglets */}
      <div className="tab-content-container">
        {/* Liste des articles */}
        {activeTab === 'liste-stock' && (
          <div id="liste-stock" className="tab-content active">
            <h2>Liste des Articles en Stock</h2>
            
            {/* Catégories et sous-catégories */}
            {categories.length > 0 && (
              <div className="categories-container">
                <h3>Catégories</h3>
                <div className="category-buttons">
                  <button 
                    className={`category-btn ${selectedCategory === '' ? 'active' : ''}`}
                    onClick={() => {
                      setSelectedCategory('');
                      setSelectedSubCategory('');
                    }}
                  >
                    Toutes
                  </button>
                  {categories.map((category, index) => (
                    <button 
                      key={index} 
                      className={`category-btn ${selectedCategory === category ? 'active' : ''}`}
                      onClick={() => setSelectedCategory(category)}
                    >
                      {category.charAt(0).toUpperCase() + category.slice(1)}
                    </button>
                  ))}
                </div>
                
                {/* Sous-catégories (visibles uniquement quand une catégorie est sélectionnée) */}
                {selectedCategory && subCategories[selectedCategory] && subCategories[selectedCategory].length > 0 && (
                  <div className="subcategories-container">
                    <h4>Sous-catégories</h4>
                    <div className="subcategory-buttons">
                      <button 
                        className={`subcategory-btn ${selectedSubCategory === '' ? 'active' : ''}`}
                        onClick={() => setSelectedSubCategory('')}
                      >
                        Toutes
                      </button>
                      {subCategories[selectedCategory].map((subCategory, index) => (
                        <button 
                          key={index} 
                          className={`subcategory-btn ${selectedSubCategory === subCategory ? 'active' : ''}`}
                          onClick={() => setSelectedSubCategory(subCategory)}
                        >
                          {subCategory.charAt(0).toUpperCase() + subCategory.slice(1)}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            
            {/* Recherche et scanner */}
            <div className="filters-container">
              <div className="search-container">
                <input 
                  type="text" 
                  id="search-stock" 
                  placeholder="Rechercher un article..." 
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </div>
              
              <div className="barcode-actions">
                <button 
                  className="scanner-btn"
                  onClick={() => {
                    setScanTarget('search');
                    setShowScanner(true);
                  }}
                  title="Scanner pour rechercher"
                >
                  <i className="scanner-icon">🔍</i>
                </button>
                <button 
                  className="scanner-btn"
                  onClick={() => {
                    setScanTarget('add');
                    setShowScanner(true);
                  }}
                  title="Scanner pour ajouter"
                >
                  <i className="scanner-icon">➕</i>
                </button>
                <button 
                  className="scanner-btn"
                  onClick={() => {
                    setScanTarget('update');
                    setShowScanner(true);
                  }}
                  title="Scanner pour ajuster le stock"
                >
                  <i className="scanner-icon">📦</i>
                </button>
              </div>
            </div>
            
            {/* Liste des articles */}
            <StockItemsList 
              items={filteredItems}
              onEdit={editStockItemHandler}
              onDelete={deleteStockItem}
              onUpdateStock={updateStockLevel}
            />
          </div>
        )}
        
        {/* Nouvel article */}
        {activeTab === 'nouveau-stock' && (
          <div id="nouveau-stock" className="tab-content active">
            <h2>{editItem && editItem.id ? 'Modifier un Article' : 'Nouvel Article'}</h2>
            <StockForm 
              initialData={editItem}
              onSave={editItem && editItem.id ? updateStockItem : addStockItem}
              categories={categories}
              onCancel={() => {
                setEditItem(null);
                setActiveTab('liste-stock');
              }}
            />
          </div>
        )}
      </div>
      
      {/* Scanner de code-barres (affiché conditionnellement) */}
      {showScanner && (
        <BarcodeScanner 
          onDetected={handleBarcodeDetected} 
          onClose={() => setShowScanner(false)}
        />
      )}
    </section>
  );
};