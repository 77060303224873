import React, { useState, useEffect } from 'react';
import './Agenda.css';

export const AppointmentModal = ({ isOpen, onClose, onSave, initialData, existingAppointment, settings = {} }) => {
  // État pour gérer les données du formulaire
  const [formData, setFormData] = useState({
    id: null,
    date: new Date().toISOString().split('T')[0], // Format YYYY-MM-DD
    start: "09:00",
    duration: 60,
    client_name: "",
    client_phone: "",
    client_email: "",
    type: "consultation",
    notes: "",
    color: "#c6b291", // Couleur par défaut, mais sera remplacée par celle de la prestation si disponible
    // Champs pour la récurrence
    isRecurring: false,
    recurrenceType: "weekly", // weekly, biweekly, monthly, custom
    recurrenceInterval: 1,
    recurrenceEndDate: "",
    recurrenceEndAfter: 10,
    recurrenceEndType: "after", // after, on
    // Champs pour les rappels
    reminder: false,
    reminderTime: settings.defaultReminderTime || 60 // Minutes avant le RDV
  });

  // États pour validation
  const [errors, setErrors] = useState({});
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  
  // État pour les options avancées
  const [showAdvanced, setShowAdvanced] = useState(false);
  
  // État pour les prestations
  const [prestations, setPrestations] = useState([]);
  const [selectedPrestationId, setSelectedPrestationId] = useState("custom");
  
  // Options de récurrence disponibles
  const recurrenceOptions = [
    { value: "daily", label: "Tous les jours" },
    { value: "weekly", label: "Toutes les semaines" },
    { value: "biweekly", label: "Toutes les deux semaines" },
    { value: "monthly", label: "Tous les mois" },
    { value: "custom", label: "Personnalisé" }
  ];
  
  // Options de rappel
  const reminderOptions = [
    { value: 15, label: "15 minutes avant" },
    { value: 30, label: "30 minutes avant" },
    { value: 60, label: "1 heure avant" },
    { value: 120, label: "2 heures avant" },
    { value: 1440, label: "1 jour avant" },
    { value: 2880, label: "2 jours avant" }
  ];

  // Charger les prestations
  useEffect(() => {
    const storedPrestations = JSON.parse(localStorage.getItem("prestations") || "[]");
    setPrestations(storedPrestations);
  }, []);

  // Chargement des données existantes si c'est une modification
  useEffect(() => {
    if (existingAppointment) {
      setFormData({
        ...existingAppointment
      });
      
      // Si le rendez-vous est récurrent, afficher les options avancées
      if (existingAppointment.isRecurring) {
        setShowAdvanced(true);
      }
      
      // Trouver la prestation correspondante si elle existe
      if (existingAppointment.type) {
        const matchingPrestation = prestations.find(p => p.nom === existingAppointment.type);
        if (matchingPrestation) {
          setSelectedPrestationId(matchingPrestation.id.toString());
        } else {
          setSelectedPrestationId("custom");
        }
      }
    } else if (initialData) {
      // Pour un nouveau rendez-vous avec des données initiales
      setFormData(prevData => ({
        ...prevData,
        date: initialData.date || prevData.date,
        start: initialData.time || prevData.start,
        duration: initialData.duration || prevData.duration,
        id: initialData.appointmentId || null
      }));
    }
    
    // Initialiser la date de fin de récurrence à 3 mois après la date du rendez-vous
    if (!existingAppointment) {
      const endDate = new Date(formData.date);
      endDate.setMonth(endDate.getMonth() + 3);
      setFormData(prevData => ({
        ...prevData,
        recurrenceEndDate: endDate.toISOString().split('T')[0]
      }));
    }
  }, [existingAppointment, initialData, prestations]);

  // Fonction pour récupérer les clients depuis le localStorage
  const fetchClientSuggestions = (searchTerm) => {
    try {
      // Récupération des clients du localStorage
      const clients = JSON.parse(localStorage.getItem("clients") || "[]");
      
      // Filtrer les clients qui correspondent au terme de recherche
      const filteredClients = clients.filter(client => 
        client.nom?.toLowerCase().includes(searchTerm.toLowerCase()) || 
        client.prenom?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      
      return filteredClients.map(client => ({
        id: client.id,
        name: `${client.prenom} ${client.nom}`,
        phone: client.telephone,
        email: client.email
      }));
    } catch (error) {
      console.error("Erreur lors de la récupération des clients:", error);
      return [];
    }
  };

  // Gestion du changement de client avec suggestions
  const handleClientNameChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, client_name: value });
    
    if (value.length > 2) {
      const suggestions = fetchClientSuggestions(value);
      setClientSuggestions(suggestions);
      setShowSuggestions(suggestions.length > 0);
    } else {
      setShowSuggestions(false);
    }
  };

  // Sélection d'un client dans les suggestions
  const selectClient = (client) => {
    setFormData({
      ...formData,
      client_name: client.name,
      client_phone: client.phone || formData.client_phone,
      client_email: client.email || formData.client_email
    });
    setShowSuggestions(false);
  };

  // Gestion des changements dans le formulaire
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Pour les cases à cocher, utilisez checked au lieu de value
    const newValue = type === 'checkbox' ? checked : value;
    
    setFormData({
      ...formData,
      [name]: newValue
    });

    // Nettoyage des erreurs lors de la modification
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }
    
    // Logique additionnelle pour certains champs
    if (name === "isRecurring" && checked) {
      // Si on active la récurrence, calculer une date de fin par défaut (3 mois plus tard)
      const endDate = new Date(formData.date);
      endDate.setMonth(endDate.getMonth() + 3);
      
      setFormData(prevData => ({
        ...prevData,
        isRecurring: checked,
        recurrenceEndDate: endDate.toISOString().split('T')[0]
      }));
    }
    
    // Pour le type de récurrence
    if (name === "recurrenceType") {
      // Réinitialiser l'intervalle selon le type
      const defaultIntervals = {
        daily: 1,
        weekly: 1,
        biweekly: 2,
        monthly: 1,
        custom: 1
      };
      
      setFormData(prevData => ({
        ...prevData,
        recurrenceType: value,
        recurrenceInterval: defaultIntervals[value] || 1
      }));
    }
  };

  // Sélection d'une prestation prédéfinie
  const handlePrestationSelect = (e) => {
    const prestationId = e.target.value;
    setSelectedPrestationId(prestationId);
    
    if (prestationId === "custom") return;
    
    const selectedPrestation = prestations.find(p => p.id.toString() === prestationId);
    
    if (selectedPrestation) {
      setFormData({
        ...formData,
        type: selectedPrestation.nom,
        duration: selectedPrestation.duree || formData.duration,
        color: selectedPrestation.couleur || "#c6b291" // Utiliser la couleur de la prestation
      });
    }
  };

  // Gestion des changements de durée avec les boutons + et -
  const handleDurationChange = (increment) => {
    const newDuration = Math.max(5, parseInt(formData.duration) + increment);
    setFormData({
      ...formData,
      duration: newDuration
    });
  };

  // Validation du formulaire
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.date) {
      newErrors.date = "La date est requise";
    }
    
    if (!formData.start) {
      newErrors.start = "L'heure de début est requise";
    }
    
    if (!formData.duration || formData.duration <= 0) {
      newErrors.duration = "La durée doit être supérieure à 0";
    }
    
    if (!formData.client_name) {
      newErrors.client_name = "Le nom du client est requis";
    }
    
    // Validation spécifique pour la récurrence
    if (formData.isRecurring) {
      if (formData.recurrenceEndType === "on" && !formData.recurrenceEndDate) {
        newErrors.recurrenceEndDate = "La date de fin de récurrence est requise";
      }
      
      if (formData.recurrenceEndType === "after" && 
          (!formData.recurrenceEndAfter || formData.recurrenceEndAfter <= 0)) {
        newErrors.recurrenceEndAfter = "Le nombre d'occurrences doit être supérieur à 0";
      }
      
      if (formData.recurrenceInterval <= 0) {
        newErrors.recurrenceInterval = "L'intervalle doit être supérieur à 0";
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      const dataToSave = { ...formData };
      
      // Convertir les valeurs numériques
      dataToSave.duration = parseInt(dataToSave.duration);
      if (dataToSave.isRecurring) {
        dataToSave.recurrenceInterval = parseInt(dataToSave.recurrenceInterval);
        if (dataToSave.recurrenceEndType === "after") {
          dataToSave.recurrenceEndAfter = parseInt(dataToSave.recurrenceEndAfter);
        }
      }
      
      if (dataToSave.reminder) {
        dataToSave.reminderTime = parseInt(dataToSave.reminderTime);
      }
      
      // S'assurer que la couleur est bien définie
      if (selectedPrestationId !== "custom") {
        const selectedPrestation = prestations.find(p => p.id.toString() === selectedPrestationId);
        if (selectedPrestation) {
          // S'assurer que la couleur de la prestation est utilisée
          dataToSave.prestationId = selectedPrestationId;
          dataToSave.color = selectedPrestation.couleur || "#c6b291";
          console.log("Couleur du rendez-vous enregistrée:", dataToSave.color);
        }
      }
      
      onSave(dataToSave);
    }
  };

  // Formatage de l'heure pour l'affichage
  const formatTimeRange = () => {
    if (!formData.start || !formData.duration) return "";
    
    const [startHour, startMinute] = formData.start.split(':').map(Number);
    const startDate = new Date();
    startDate.setHours(startHour, startMinute, 0);
    
    const endDate = new Date(startDate);
    endDate.setMinutes(endDate.getMinutes() + parseInt(formData.duration));
    
    const formatTime = (date) => {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    };
    
    return `${formData.start} - ${formatTime(endDate)}`;
  };
  
  // Générer un résumé de la récurrence pour affichage
  const generateRecurrenceSummary = () => {
    if (!formData.isRecurring) return "";
    
    let summary = "";
    
    switch (formData.recurrenceType) {
      case "daily":
        summary = formData.recurrenceInterval === 1 
          ? "Tous les jours" 
          : `Tous les ${formData.recurrenceInterval} jours`;
        break;
      case "weekly":
        summary = formData.recurrenceInterval === 1 
          ? "Toutes les semaines" 
          : `Toutes les ${formData.recurrenceInterval} semaines`;
        break;
      case "biweekly":
        summary = "Toutes les deux semaines";
        break;
      case "monthly":
        summary = formData.recurrenceInterval === 1 
          ? "Tous les mois" 
          : `Tous les ${formData.recurrenceInterval} mois`;
        break;
      case "custom":
        summary = `Tous les ${formData.recurrenceInterval} jours`;
        break;
      default:
        summary = "Personnalisé";
    }
    
    if (formData.recurrenceEndType === "after") {
      summary += `, ${formData.recurrenceEndAfter} fois`;
    } else {
      // Formater la date de fin
      const endDate = new Date(formData.recurrenceEndDate);
      summary += `, jusqu'au ${endDate.toLocaleDateString('fr-FR')}`;
    }
    
    return summary;
  };

  // Formatter la durée pour l'affichage
  const formatDuration = (minutes) => {
    if (!minutes) return 'Durée non spécifiée';
    
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    
    if (hours === 0) {
      return `${mins} min`;
    } else if (mins === 0) {
      return `${hours} h`;
    } else {
      return `${hours} h ${mins} min`;
    }
  };

  // Si le modal n'est pas ouvert, ne rien afficher
  if (!isOpen) return null;

  // Vérifier si les fonctionnalités avancées sont activées
  const showRecurrence = settings.enableRecurrence || formData.isRecurring;
  const showReminders = settings.enableReminders || formData.reminder;
  const showClientIntegration = settings.enableClientIntegration;
  
  // Obtenir la couleur de la prestation sélectionnée
  const getSelectedPrestationColor = () => {
    if (selectedPrestationId !== "custom") {
      const selectedPrestation = prestations.find(p => p.id.toString() === selectedPrestationId);
      if (selectedPrestation && selectedPrestation.couleur) {
        return selectedPrestation.couleur;
      }
    }
    return formData.color || "#c6b291";
  };

  return (
    <div className="modal appointment-modal" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <span className="close-modal" onClick={onClose}>&times;</span>
        <h2>{existingAppointment ? "Modifier le rendez-vous" : "Nouveau rendez-vous"}</h2>
        
        <form onSubmit={handleSubmit}>
          {/* Date et Heure */}
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="date">Date:</label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className={errors.date ? "error" : ""}
              />
              {errors.date && <div className="error-message">{errors.date}</div>}
            </div>
            
            <div className="form-group">
              <label htmlFor="start">Heure de début:</label>
              <input
                type="time"
                id="start"
                name="start"
                value={formData.start}
                onChange={handleInputChange}
                className={errors.start ? "error" : ""}
              />
              {errors.start && <div className="error-message">{errors.start}</div>}
            </div>
          </div>

          {/* Sélection de prestation prédéfinie */}
          <div className="form-group">
            <label htmlFor="prestation-select">Choisir une prestation:</label>
            <select 
              id="prestation-select" 
              onChange={handlePrestationSelect}
              value={selectedPrestationId}
            >
              <option value="custom">Personnalisé</option>
              {prestations.map(prestation => (
                <option key={prestation.id} value={prestation.id.toString()}>
                  {prestation.nom} - {formatDuration(prestation.duree)} - {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(prestation.prixTTC || prestation.prix || 0)}
                </option>
              ))}
            </select>
          </div>

          {/* Durée avec contrôles + et - */}
          <div className="form-group">
            <label htmlFor="duration">Durée (minutes):</label>
            <div className="duration-control">
              <button 
                type="button" 
                className="time-control-btn"
                onClick={() => handleDurationChange(-5)}
              >-</button>
              <input
                type="number"
                id="duration"
                name="duration"
                value={formData.duration}
                onChange={handleInputChange}
                min="5"
                step="5"
                className={errors.duration ? "error" : ""}
              />
              <button 
                type="button" 
                className="time-control-btn"
                onClick={() => handleDurationChange(5)}
              >+</button>
              <span className="time-range-preview">{formatTimeRange()}</span>
            </div>
            {errors.duration && <div className="error-message">{errors.duration}</div>}
          </div>

          {/* Informations du client */}
          <div className="form-group">
            <label htmlFor="client_name">Nom du client:</label>
            <div className="suggestion-container">
              <input
                type="text"
                id="client_name"
                name="client_name"
                value={formData.client_name}
                onChange={handleClientNameChange}
                className={errors.client_name ? "error" : ""}
                placeholder="Rechercher un client..."
              />
              {showSuggestions && (
                <div className="client-suggestions">
                  {clientSuggestions.map(client => (
                    <div 
                      key={client.id} 
                      className="client-suggestion-item"
                      onClick={() => selectClient(client)}
                    >
                      {client.name} {client.phone && `- ${client.phone}`}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {errors.client_name && <div className="error-message">{errors.client_name}</div>}
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="client_phone">Téléphone:</label>
              <input
                type="tel"
                id="client_phone"
                name="client_phone"
                value={formData.client_phone || ""}
                onChange={handleInputChange}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="client_email">Email:</label>
              <input
                type="email"
                id="client_email"
                name="client_email"
                value={formData.client_email || ""}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Type de rendez-vous */}
          <div className="form-group">
            <label htmlFor="type">Type de rendez-vous:</label>
            <input
              type="text"
              id="type"
              name="type"
              value={formData.type}
              onChange={handleInputChange}
              placeholder="Ex: Consultation, Suivi, Soin visage..."
            />
          </div>

          {/* Affichage de la couleur du rendez-vous (sans possibilité de modifier) */}
          <div className="form-group">
            <label>Couleur (basée sur la prestation):</label>
            <div 
              className="color-preview" 
              style={{ backgroundColor: getSelectedPrestationColor() }}
            >
              Aperçu du rendez-vous
            </div>
          </div>

          {/* Bouton pour afficher/masquer les options avancées */}
          <button 
            type="button" 
            className="toggle-advanced-btn"
            onClick={() => setShowAdvanced(!showAdvanced)}
          >
            {showAdvanced ? "Masquer les options avancées" : "Afficher les options avancées"}
          </button>
          
          {/* Options avancées */}
          {showAdvanced && (
            <div className="advanced-options">
              {/* Récurrence */}
              {showRecurrence && (
                <div className="recurrence-section">
                  <h3>Récurrence</h3>
                  
                  <div className="form-group">
                    <div className="checkbox-group">
                      <input
                        type="checkbox"
                        id="isRecurring"
                        name="isRecurring"
                        checked={formData.isRecurring}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="isRecurring">Rendez-vous récurrent</label>
                    </div>
                  </div>
                  
                  {formData.isRecurring && (
                    <>
                      <div className="recurrence-summary">
                        {generateRecurrenceSummary()}
                      </div>
                      
                      <div className="form-group">
                        <label htmlFor="recurrenceType">Fréquence:</label>
                        <select
                          id="recurrenceType"
                          name="recurrenceType"
                          value={formData.recurrenceType}
                          onChange={handleInputChange}
                        >
                          {recurrenceOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      
                      {formData.recurrenceType === "custom" && (
                        <div className="form-group">
                          <label htmlFor="recurrenceInterval">Tous les:</label>
                          <div className="interval-input">
                            <input
                              type="number"
                              id="recurrenceInterval"
                              name="recurrenceInterval"
                              value={formData.recurrenceInterval}
                              onChange={handleInputChange}
                              min="1"
                              className={errors.recurrenceInterval ? "error" : ""}
                            />
                            <span>jours</span>
                          </div>
                          {errors.recurrenceInterval && (
                            <div className="error-message">{errors.recurrenceInterval}</div>
                          )}
                        </div>
                      )}
                      
                      <div className="form-group">
                        <label>Fin de récurrence:</label>
                        <div className="radio-options">
                          <div className="radio-option">
                            <input
                              type="radio"
                              id="recurrenceEndAfterRadio"
                              name="recurrenceEndType"
                              value="after"
                              checked={formData.recurrenceEndType === "after"}
                              onChange={handleInputChange}
                            />
                            <label htmlFor="recurrenceEndAfterRadio">Après</label>
                            <input
                              type="number"
                              id="recurrenceEndAfter"
                              name="recurrenceEndAfter"
                              value={formData.recurrenceEndAfter}
                              onChange={handleInputChange}
                              min="1"
                              disabled={formData.recurrenceEndType !== "after"}
                              className={errors.recurrenceEndAfter ? "error" : ""}
                            />
                            <span>occurrences</span>
                          </div>
                          
                          <div className="radio-option">
                            <input
                              type="radio"
                              id="recurrenceEndOnRadio"
                              name="recurrenceEndType"
                              value="on"
                              checked={formData.recurrenceEndType === "on"}
                              onChange={handleInputChange}
                            />
                            <label htmlFor="recurrenceEndOnRadio">Le</label>
                            <input
                              type="date"
                              id="recurrenceEndDate"
                              name="recurrenceEndDate"
                              value={formData.recurrenceEndDate}
                              onChange={handleInputChange}
                              disabled={formData.recurrenceEndType !== "on"}
                              className={errors.recurrenceEndDate ? "error" : ""}
                            />
                          </div>
                        </div>
                        
                        {errors.recurrenceEndAfter && formData.recurrenceEndType === "after" && (
                          <div className="error-message">{errors.recurrenceEndAfter}</div>
                        )}
                        
                        {errors.recurrenceEndDate && formData.recurrenceEndType === "on" && (
                          <div className="error-message">{errors.recurrenceEndDate}</div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
              
              {/* Rappels */}
              {showReminders && (
                <div className="reminders-section">
                  <h3>Rappels</h3>
                  
                  <div className="form-group">
                    <div className="checkbox-group">
                      <input
                        type="checkbox"
                        id="reminder"
                        name="reminder"
                        checked={formData.reminder}
                        onChange={handleInputChange}
                      />
                      <label htmlFor="reminder">Activer le rappel</label>
                    </div>
                  </div>
                  
                  {formData.reminder && (
                    <div className="form-group">
                      <label htmlFor="reminderTime">Rappel:</label>
                      <select
                        id="reminderTime"
                        name="reminderTime"
                        value={formData.reminderTime}
                        onChange={handleInputChange}
                      >
                        {reminderOptions.map(option => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {/* Notes */}
          <div className="form-group">
            <label htmlFor="notes">Notes:</label>
            <textarea
              id="notes"
              name="notes"
              value={formData.notes || ""}
              onChange={handleInputChange}
              rows="3"
              placeholder="Notes additionnelles..."
            ></textarea>
          </div>

          {/* Boutons d'action */}
          <div className="form-actions">
            <button type="submit" className="submit-btn">
              {existingAppointment ? "Mettre à jour" : "Enregistrer"}
            </button>
            {existingAppointment && (
              <button 
                type="button" 
                className="delete-btn" 
                onClick={() => {
                  if (window.confirm("Êtes-vous sûr de vouloir supprimer ce rendez-vous ?")) {
                    onClose();
                    const deleteAppointment = window.deleteAppointment || (() => {});
                    deleteAppointment(existingAppointment.id);
                  }
                }}
              >
                Supprimer
              </button>
            )}
            <button type="button" className="cancel-btn" onClick={onClose}>Annuler</button>
          </div>
        </form>
      </div>
    </div>
  );
};