// src/services/DataSyncService.js

/**
 * Service de synchronisation des données entre le stockage local et le serveur
 * Gère l'authentification, les conflits et les sauvegardes
 * Fonctionne automatiquement en arrière-plan
 */
class DataSyncServiceClass {
  constructor() {
    this.syncListeners = [];
    this.isSyncing = false;
    this.syncInterval = null;
    this.lastSync = null;
    this.syncQueue = [];
    this.retryAttempts = 0;
    this.maxRetries = 3;
    this.serverUrl = this.getServerUrl();
    
    // Ajouter des gestionnaires d'événements pour le cycle de vie de l'application
    this.setupLifecycleHandlers();
    
    // Initialiser la synchronisation automatique
    this.initAutoSync();
  }

  /**
   * Obtenir l'URL du serveur en fonction de l'environnement
   */
  getServerUrl() {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
      ? 'http://localhost:8000/api'
      : '/api';
  }

  /**
   * Configure les gestionnaires d'événements pour le cycle de vie de l'application
   */
  setupLifecycleHandlers() {
    // Synchroniser avant la fermeture de la page (version simplifiée sans quickSync)
    window.addEventListener('beforeunload', () => {
      if (navigator.onLine && !this.isSyncing) {
        // Version synchrone simplifiée pour beforeunload
        try {
          // Juste essayer de traiter la file d'attente sans attendre
          this.processQueuedSyncs().catch(err => {
            console.error('Erreur lors de la synchronisation de fermeture:', err);
          });
        } catch (err) {
          console.error('Erreur lors de la synchronisation de fermeture:', err);
        }
      }
    });
    
    // Synchroniser lorsque l'application revient au premier plan
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.syncAllData().catch(err => {
          console.error('Erreur lors de la synchronisation au retour:', err);
        });
      }
    });
    
    // Synchroniser lorsque la connexion réseau est rétablie
    window.addEventListener('online', () => {
      console.log('Connexion réseau rétablie, synchronisation en cours...');
      this.processQueuedSyncs();
      this.syncAllData().catch(err => {
        console.error('Erreur lors de la synchronisation après connexion:', err);
      });
    });
    
    // Stocker les demandes de synchronisation en file d'attente en mode hors ligne
    window.addEventListener('offline', () => {
      console.log('Mode hors ligne détecté');
      this.notifySyncListeners('networkStatusChange', { isOnline: false });
    });
  }

  /**
   * Initialiser la synchronisation automatique
   */
  initAutoSync() {
    // Arrêter l'intervalle existant s'il y en a un
    if (this.syncInterval) {
      clearInterval(this.syncInterval);
    }
    
    // Récupérer les paramètres de synchronisation
    const syncSettings = this.getSyncSettings();
    
    // Toujours configurer l'intervalle automatique
    const intervalMinutes = syncSettings.syncFrequency || 5;
    console.log(`Synchronisation automatique configurée: toutes les ${intervalMinutes} minutes`);
    
    this.syncInterval = setInterval(() => {
      this.autoSyncInterval();
    }, intervalMinutes * 60 * 1000);
    
    // Effectuer une synchronisation initiale
    setTimeout(() => {
      this.syncAllData().catch(err => {
        console.error('Erreur lors de la synchronisation initiale:', err);
        // Ajouter à la file d'attente pour réessayer plus tard
        this.addToSyncQueue('full');
      });
    }, 5000); // Attendre 5 secondes pour permettre à l'application de s'initialiser
  }

  /**
   * Obtenir les paramètres de synchronisation
   */
  getSyncSettings() {
    try {
      const settings = JSON.parse(localStorage.getItem('sync_settings') || '{}');
      return {
        autoSyncEnabled: true,
        syncFrequency: settings.syncFrequency || 5,
        syncOnStartup: true,
        syncOnShutdown: true,
        syncWifiOnly: false,
        syncAppointmentDays: settings.syncAppointmentDays || 90,
        syncTransactionDays: settings.syncTransactionDays || 90,
        conflictResolution: settings.conflictResolution || 'askUser'
      };
    } catch (erreur) {
      console.error('Erreur lors de la récupération des paramètres de synchronisation:', erreur);
      return {
        autoSyncEnabled: true,
        syncFrequency: 5,
        syncOnStartup: true,
        syncOnShutdown: true,
        syncWifiOnly: false,
        syncAppointmentDays: 90,
        syncTransactionDays: 90,
        conflictResolution: 'askUser'
      };
    }
  }

  /**
   * Mettre à jour les paramètres de synchronisation
   */
  updateSyncSettings(newSettings) {
    try {
      const currentSettings = this.getSyncSettings();
      const updatedSettings = { 
        ...currentSettings, 
        ...newSettings,
        autoSyncEnabled: true,
        syncOnStartup: true,
        syncOnShutdown: true
      };
      
      localStorage.setItem('sync_settings', JSON.stringify(updatedSettings));
      
      // Réinitialiser la synchronisation automatique
      this.initAutoSync();
      
      return updatedSettings;
    } catch (erreur) {
      console.error('Erreur lors de la mise à jour des paramètres de synchronisation:', erreur);
      throw erreur;
    }
  }

  /**
   * Exécuté à l'intervalle de synchronisation automatique
   */
  async autoSyncInterval() {
    console.log('Exécution de la synchronisation automatique planifiée');
    
    // Vérifier si nous sommes déjà en train de synchroniser
    if (this.isSyncing) {
      console.log('Une synchronisation est déjà en cours, planification ignorée');
      return;
    }
    
    try {
      await this.syncAllData();
    } catch (erreur) {
      console.error('Erreur lors de la synchronisation automatique:', erreur);
      // Ajouter à la file d'attente pour réessayer plus tard
      this.addToSyncQueue('full');
    }
  }

  /**
   * Ajouter un écouteur d'événements de synchronisation
   */
  addSyncListener(callback) {
    if (typeof callback === 'function') {
      this.syncListeners.push(callback);
    }
    
    // Retourner une fonction pour se désabonner
    return () => {
      this.syncListeners = this.syncListeners.filter(cb => cb !== callback);
    };
  }

  /**
   * Notifier tous les écouteurs d'un événement de synchronisation
   */
  notifySyncListeners(evenement, donnees = {}) {
    this.syncListeners.forEach(callback => {
      try {
        callback(evenement, donnees);
      } catch (erreur) {
        console.error('Erreur dans l\'écouteur de synchronisation:', erreur);
      }
    });
  }

  /**
   * Récupérer le token CSRF
   */
  getCsrfToken() {
    return document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1] || '';
  }

  /**
   * Requête HTTP sécurisée avec gestion avancée des erreurs
   */
  async requeteSecurisee(url, options = {}) {
    try {
      const baseUrl = this.serverUrl;
      const fullUrl = url.startsWith('http') ? url : `${baseUrl}${url}`;
      
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.getCsrfToken()
      };
      
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      }
      
      const configRequete = {
        ...options,
        headers: {
          ...headers,
          ...(options.headers || {})
        }
      };
      
      // Vérifier la connexion internet
      if (!navigator.onLine) {
        throw new Error('Mode hors ligne, impossible de contacter le serveur');
      }
      
      // Ajouter un timeout
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 15000); // 15 secondes timeout
      configRequete.signal = controller.signal;
      
      const reponse = await fetch(fullUrl, configRequete);
      clearTimeout(timeoutId);
      
      // Gérer les réponses selon le code HTTP
      if (reponse.status === 401) {
        // Token expiré, essayer de le rafraîchir
        const refreshed = await this.refreshAccessToken();
        if (refreshed) {
          // Réessayer avec le nouveau token
          return this.requeteSecurisee(url, options);
        } else {
          throw new Error('Session expirée, veuillez vous reconnecter');
        }
      }
      
      if (reponse.status === 403) {
        throw new Error('Accès refusé. Vous n\'avez pas les permissions nécessaires');
      }
      
      if (reponse.status === 404) {
        // API non existante - retourner un objet simulé pour ne pas bloquer
        console.warn(`API non trouvée: ${fullUrl}`);
        return { notFound: true };
      }
      
      if (reponse.status === 409) {
        // Conflit détecté
        const conflit = await reponse.json();
        return { conflit: true, donnees: conflit };
      }
      
      if (!reponse.ok) {
        throw new Error(`Erreur HTTP: ${reponse.status}`);
      }
      
      const contentType = reponse.headers.get('Content-Type');
      
      if (contentType && contentType.includes('application/json')) {
        return await reponse.json();
      } else {
        return { success: true };
      }
    } catch (erreur) {
      console.error(`Erreur lors de la requête à ${url}:`, erreur);
      
      // Erreurs spécifiques
      if (erreur.name === 'AbortError') {
        throw new Error('La requête a pris trop de temps et a été annulée');
      }
      
      // Gestion des erreurs réseau
      if (erreur instanceof TypeError && erreur.message.includes('fetch')) {
        // Problème de connexion
        throw new Error('Impossible de se connecter au serveur. Vérifiez votre connexion internet.');
      }
      
      throw erreur;
    }
  }

  /**
   * Rafraîchir le token d'accès
   */
  async refreshAccessToken() {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        return false;
      }
      
      const response = await fetch(`${this.serverUrl}/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ refresh: refreshToken })
      });
      
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('accessToken', data.access);
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Erreur lors du rafraîchissement du token:', error);
      return false;
    }
  }

  /**
   * Ajouter une tâche à la file d'attente de synchronisation
   */
  addToSyncQueue(type, data = {}) {
    const queueItem = {
      type,
      data,
      timestamp: new Date().toISOString()
    };
    
    this.syncQueue.push(queueItem);
    
    // Stocker la file d'attente dans localStorage
    try {
      localStorage.setItem('sync_queue', JSON.stringify(this.syncQueue));
      console.log(`Tâche de synchronisation ajoutée à la file d'attente: ${type}`);
    } catch (error) {
      console.error('Erreur lors de l\'ajout à la file d\'attente:', error);
    }
  }

  /**
   * Traiter la file d'attente de synchronisation
   */
  async processQueuedSyncs() {
    try {
      if (!navigator.onLine) {
        console.log('Mode hors ligne, traitement de la file d\'attente reporté');
        return;
      }
      
      // Récupérer la file d'attente depuis localStorage
      try {
        this.syncQueue = JSON.parse(localStorage.getItem('sync_queue') || '[]');
      } catch (error) {
        console.error('Erreur lors de la récupération de la file d\'attente:', error);
        this.syncQueue = [];
      }
      
      if (this.syncQueue.length === 0) {
        return;
      }
      
      console.log(`Traitement de ${this.syncQueue.length} tâches en file d'attente`);
      
      const queueCopy = [...this.syncQueue];
      let processedCount = 0;
      
      for (const task of queueCopy) {
        try {
          switch (task.type) {
            case 'client':
              await this.pushClientToServer(task.data);
              break;
            case 'appointment':
              await this.pushAppointmentToServer(task.data);
              break;
            case 'transaction':
              await this.pushTransactionToServer(task.data);
              break;
            case 'service':
              await this.pushServiceToServer(task.data);
              break;
            case 'stock':
              await this.pushStockItemToServer(task.data);
              break;
            case 'full':
              await this.syncAllData();
              break;
            default:
              console.warn(`Type de tâche inconnu: ${task.type}`);
          }
          
          // Supprimer la tâche de la file d'attente
          this.syncQueue = this.syncQueue.filter(t => 
            t.timestamp !== task.timestamp || t.type !== task.type
          );
          
          processedCount++;
        } catch (error) {
          console.error(`Erreur lors du traitement de la tâche ${task.type}:`, error);
          // Laisser la tâche dans la file pour réessayer plus tard
        }
      }
      
      // Mettre à jour la file d'attente dans le localStorage
      localStorage.setItem('sync_queue', JSON.stringify(this.syncQueue));
      
      console.log(`${processedCount} tâches traitées, ${this.syncQueue.length} restantes`);
      
      // Notifier si toutes les tâches sont terminées
      if (this.syncQueue.length === 0) {
        this.notifySyncListeners('queueProcessed', { initialSize: queueCopy.length });
      }
    } catch (error) {
      console.error('Erreur lors du traitement de la file d\'attente:', error);
    }
  }

  /**
   * Vérifier le statut des files d'attente
   * @returns {Object} Statut des files d'attente
   */
  checkQueueStatus() {
    try {
      // Récupérer les files d'attente depuis le localStorage
      const authQueue = JSON.parse(localStorage.getItem('auth_request_queue') || '[]');
      const networkQueue = JSON.parse(localStorage.getItem('network_request_queue') || '[]');
      const syncQueue = JSON.parse(localStorage.getItem('sync_queue') || '[]');
      
      return {
        hasPendingAuthRequests: authQueue.length > 0,
        authQueueLength: authQueue.length,
        hasPendingNetworkRequests: networkQueue.length > 0,
        networkQueueLength: networkQueue.length,
        hasPendingSyncRequests: syncQueue.length > 0,
        syncQueueLength: syncQueue.length,
        hasAnyPendingRequests: authQueue.length > 0 || networkQueue.length > 0 || syncQueue.length > 0
      };
    } catch (error) {
      console.error('Erreur lors de la vérification des files d\'attente:', error);
      return {
        hasPendingAuthRequests: false,
        authQueueLength: 0,
        hasPendingNetworkRequests: false,
        networkQueueLength: 0,
        hasPendingSyncRequests: false,
        syncQueueLength: 0,
        hasAnyPendingRequests: false
      };
    }
  }

  /**
   * Traiter les requêtes en attente
   */
  async processQueuedRequests() {
    try {
      // Traiter les requêtes en attente d'authentification
      await this.processAuthQueue();
      
      // Traiter les requêtes en attente de réseau
      await this.processNetworkQueue();
      
      return true;
    } catch (error) {
      console.error('Erreur lors du traitement des requêtes en attente:', error);
      return false;
    }
  }
  
  /**
   * Traiter les requêtes en attente d'authentification
   */
  async processAuthQueue() {
    try {
      const authQueue = JSON.parse(localStorage.getItem('auth_request_queue') || '[]');
      
      if (authQueue.length === 0) {
        return;
      }
      
      console.log(`Traitement de ${authQueue.length} requêtes en attente d'authentification`);
      
      // Rafraîchir d'abord le token
      const tokenRefreshed = await this.refreshAccessToken();
      
      if (!tokenRefreshed) {
        console.log('Impossible de rafraîchir le token, les requêtes restent en file d\'attente');
        this.notifySyncListeners('authExpired', {});
        return;
      }
      
      // Copier la file pour traitement
      const queueCopy = [...authQueue];
      let processedCount = 0;
      let remainingQueue = [...authQueue];
      
      for (const request of queueCopy) {
        try {
          // Effectuer la requête avec le nouveau token
          const response = await this.requeteSecurisee(
            request.url,
            {
              method: request.method,
              body: request.body ? JSON.stringify(request.body) : undefined,
              headers: request.headers || {}
            }
          );
          
          // Traiter la réponse si nécessaire
          if (request.callback) {
            try {
              // Exécuter le callback enregistré
              const callbackFn = new Function('response', request.callback);
              callbackFn(response);
            } catch (callbackError) {
              console.error('Erreur lors de l\'exécution du callback:', callbackError);
            }
          }
          
          // Retirer la requête de la file d'attente
          remainingQueue = remainingQueue.filter(req => 
            req.id !== request.id
          );
          
          processedCount++;
        } catch (error) {
          console.error(`Erreur lors du traitement de la requête ${request.url}:`, error);
          // Si c'est une erreur d'authentification, arrêter le traitement
          if (error.message.includes('expirée') || error.message.includes('connexion')) {
            break;
          }
        }
      }
      
      // Mettre à jour la file d'attente
      localStorage.setItem('auth_request_queue', JSON.stringify(remainingQueue));
      
      console.log(`${processedCount} requêtes traitées, ${remainingQueue.length} restantes`);
      
      return processedCount;
    } catch (error) {
      console.error('Erreur lors du traitement de la file d\'attente d\'authentification:', error);
      return 0;
    }
  }
  
  /**
   * Traiter les requêtes en attente de réseau
   */
  async processNetworkQueue() {
    try {
      const networkQueue = JSON.parse(localStorage.getItem('network_request_queue') || '[]');
      
      if (networkQueue.length === 0) {
        return;
      }
      
      if (!navigator.onLine) {
        console.log('Mode hors ligne, traitement de la file d\'attente réseau reporté');
        return;
      }
      
      console.log(`Traitement de ${networkQueue.length} requêtes en attente de réseau`);
      
      // Copier la file pour traitement
      const queueCopy = [...networkQueue];
      let processedCount = 0;
      let remainingQueue = [...networkQueue];
      
      for (const request of queueCopy) {
        try {
          // Effectuer la requête
          const response = await this.requeteSecurisee(
            request.url,
            {
              method: request.method,
              body: request.body ? JSON.stringify(request.body) : undefined,
              headers: request.headers || {}
            }
          );
          
          // Traiter la réponse si nécessaire
          if (request.callback) {
            try {
              // Exécuter le callback enregistré
              const callbackFn = new Function('response', request.callback);
              callbackFn(response);
            } catch (callbackError) {
              console.error('Erreur lors de l\'exécution du callback:', callbackError);
            }
          }
          
          // Retirer la requête de la file d'attente
          remainingQueue = remainingQueue.filter(req => 
            req.id !== request.id
          );
          
          processedCount++;
        } catch (error) {
          console.error(`Erreur lors du traitement de la requête ${request.url}:`, error);
          
          // Si c'est une erreur d'authentification, déplacer vers la file auth
          if (error.message.includes('expirée') || error.message.includes('connexion')) {
            const authQueue = JSON.parse(localStorage.getItem('auth_request_queue') || '[]');
            authQueue.push(request);
            localStorage.setItem('auth_request_queue', JSON.stringify(authQueue));
            
            // Retirer de la file réseau
            remainingQueue = remainingQueue.filter(req => 
              req.id !== request.id
            );
          }
          // Si c'est une erreur réseau, laisser dans la file pour réessayer plus tard
        }
      }
      
      // Mettre à jour la file d'attente
      localStorage.setItem('network_request_queue', JSON.stringify(remainingQueue));
      
      console.log(`${processedCount} requêtes traitées, ${remainingQueue.length} restantes`);
      
      return processedCount;
    } catch (error) {
      console.error('Erreur lors du traitement de la file d\'attente réseau:', error);
      return 0;
    }
  }

  /**
   * Synchroniser toutes les données
   */
  async syncAllData() {
    if (this.isSyncing) {
      console.log('Une synchronisation est déjà en cours');
      return;
    }
    
    this.isSyncing = true;
    this.notifySyncListeners('syncStart');
    
    try {
      console.log('Démarrage de la synchronisation complète...');
      
      // Traiter d'abord la file d'attente
      await this.processQueuedSyncs();
      
      const operationsSynchronisation = [
        { nom: 'clients', fonction: this.syncClients.bind(this) },
        { nom: 'services', fonction: this.syncPrestations.bind(this) },
        { nom: 'stock', fonction: this.syncStock.bind(this) },
        { nom: 'rendez-vous', fonction: this.syncAppointments.bind(this) },
        { nom: 'transactions', fonction: this.syncTransactions.bind(this) },
        { nom: 'paramètres agenda', fonction: this.syncAgendaSettings.bind(this) },
        { nom: 'paramètres caisse', fonction: this.syncCaisseSettings.bind(this) }
      ];
      
      const resultats = {
        succes: [],
        erreurs: []
      };
      
      for (const operation of operationsSynchronisation) {
        try {
          await operation.fonction();
          resultats.succes.push(operation.nom);
        } catch (erreur) {
          console.error(`Erreur lors de la synchronisation de ${operation.nom}:`, erreur);
          resultats.erreurs.push({ 
            nom: operation.nom, 
            erreur: erreur.message,
            type: erreur.name
          });
        }
      }
      
      // Gestion des erreurs de synchronisation totale
      if (resultats.erreurs.length === operationsSynchronisation.length) {
        this.notifySyncListeners('syncNetworkError', {
          message: 'Impossible de synchroniser toutes les données. Vérifiez votre connexion.',
          erreurs: resultats.erreurs
        });
      }
      
      // Mettre à jour la date de dernière synchronisation
      if (resultats.succes.length > 0) {
        this.lastSync = new Date().toISOString();
        localStorage.setItem('last_sync', this.lastSync);
      }
      
      console.log('Synchronisation complète terminée:', resultats);
      
      this.notifySyncListeners('syncComplete', {
        timestamp: this.lastSync,
        resultats: resultats
      });
      
      return resultats;
    } catch (erreur) {
      console.error('Erreur lors de la synchronisation complète:', erreur);
      this.notifySyncListeners('syncError', { erreur: erreur.message });
      throw erreur;
    } finally {
      this.isSyncing = false;
    }
  }

  /**
   * Synchroniser les clients
   */
  async syncClients() {
    try {
      console.log('Synchronisation des clients...');
      
      // Récupérer les clients locaux
      const localClients = JSON.parse(localStorage.getItem('clients') || '[]');
      
      // Si nous sommes hors ligne, juste retourner les données locales
      if (!navigator.onLine) {
        console.log('Mode hors ligne, utilisation des données locales uniquement');
        return { mode: 'offline', clients: localClients };
      }
      
      // Obtenir la dernière date de synchronisation des clients
      const lastClientSync = localStorage.getItem('last_client_sync') || null;
      
      try {
        // Récupérer les clients du serveur
        const response = await this.requeteSecurisee('/clients/', {
          method: 'GET',
          headers: {
            'If-Modified-Since': lastClientSync || ''
          }
        });
        
        // En cas d'API non trouvée ou erreur
        if (response.notFound) {
          return { mode: 'not_available', clients: localClients };
        }
        
        // Traiter les clients reçus du serveur
        if (response && response.clients) {
          // Assurez-vous que response.clients est un tableau
          const serverClients = Array.isArray(response.clients) ? response.clients : [];
          console.log(`${serverClients.length} clients reçus du serveur`);
          
          // Fusionner les clients locaux et distants
          const mergedClients = this.mergeClientsData(localClients, serverClients);
          
          // Sauvegarder les clients fusionnés localement
          localStorage.setItem('clients', JSON.stringify(mergedClients));
          localStorage.setItem('last_client_sync', new Date().toISOString());
          
          return { 
            mode: 'online', 
            clients: mergedClients,
            added: mergedClients.length - localClients.length 
          };
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des clients du serveur:', error);
        
        // En cas d'erreur, on utilise les données locales
        return { 
          mode: 'error', 
          clients: localClients,
          error: error.message
        };
      }
      
      // Si nous arrivons ici, aucune modification n'a été reçue
      return { mode: 'online', clients: localClients, added: 0 };
    } catch (erreur) {
      console.error('Erreur lors de la synchronisation des clients:', erreur);
      throw erreur;
    }
  }

  /**
   * Fusionner les données des clients locaux et distants
   */
  mergeClientsData(localClients, serverClients) {
    // Vérifier que les deux arguments sont des tableaux
    if (!Array.isArray(localClients)) localClients = [];
    if (!Array.isArray(serverClients)) serverClients = [];
    
    const mergedClients = [...localClients];
    const clientMap = new Map();
    
    // Indexer les clients locaux par ID
    localClients.forEach(client => {
      if (client && client.id) {
        clientMap.set(client.id, client);
      }
    });
    
    // Traiter les clients du serveur
    serverClients.forEach(serverClient => {
      if (!serverClient || !serverClient.id) return;
      
      const localClient = clientMap.get(serverClient.id);
      
      if (!localClient) {
        // Nouveau client, l'ajouter
        mergedClients.push(serverClient);
      } else {
        // Client existant, vérifier les dates de modification
        const serverDate = new Date(serverClient.last_modified || 0);
        const localDate = new Date(localClient.last_modified || 0);
        
        if (serverDate > localDate) {
          // La version du serveur est plus récente
          const index = mergedClients.findIndex(c => c.id === serverClient.id);
          if (index !== -1) {
            mergedClients[index] = serverClient;
          }
        } else if (serverDate < localDate) {
          // La version locale est plus récente, la conserver
          // On pourrait aussi envoyer la mise à jour au serveur
          this.pushClientToServer(localClient);
        }
      }
    });
    
    return mergedClients;
  }

  /**
   * Envoyer un client au serveur
   */
  async pushClientToServer(client) {
    try {
      if (!navigator.onLine) {
        this.addToSyncQueue('client', client);
        return { queued: true };
      }
      
      if (!client || !client.id) {
        console.error('Client invalide ou sans ID');
        return { error: 'Client invalide' };
      }
      
      const method = client.id ? 'PUT' : 'POST';
      const url = client.id ? `/clients/${client.id}/` : '/clients/';
      
      const response = await this.requeteSecurisee(url, {
        method,
        body: JSON.stringify(client)
      });
      
      return response;
    } catch (error) {
      console.error('Erreur lors de l\'envoi du client au serveur:', error);
      this.addToSyncQueue('client', client);
      throw error;
    }
  }

  /**
   * Synchroniser les prestations
   */
  async syncPrestations() {
    try {
      console.log('Synchronisation des prestations...');
      
      // Récupérer les prestations locales
      const localServices = JSON.parse(localStorage.getItem('prestations') || '[]');
      const localCategories = JSON.parse(localStorage.getItem('categories') || '[]');
      
      // Si nous sommes hors ligne, juste retourner les données locales
      if (!navigator.onLine) {
        return {
          mode: 'offline',
          prestations: localServices,
          categories: localCategories
        };
      }
      
      // Obtenir la dernière date de synchronisation
      const lastServiceSync = localStorage.getItem('last_service_sync') || null;
      
      try {
        // Récupérer les prestations du serveur
        const response = await this.requeteSecurisee(`/services/`, {
          method: 'GET',
          headers: {
            'If-Modified-Since': lastServiceSync || ''
          }
        });
        
        // En cas d'API non trouvée
        if (response.notFound) {
          return { 
            mode: 'not_available', 
            prestations: localServices, 
            categories: localCategories 
          };
        }
        
        if (response) {
          // Assurez-vous que ce sont des tableaux
          const serverServices = Array.isArray(response.services) ? response.services : [];
          const serverCategories = Array.isArray(response.categories) ? response.categories : [];
          
          // Fusionner les données
          const mergedServices = this.mergeServiceData(localServices, serverServices);
          const mergedCategories = this.mergeCategoryData(localCategories, serverCategories);
          
          // Sauvegarder localement
          localStorage.setItem('prestations', JSON.stringify(mergedServices));
          localStorage.setItem('categories', JSON.stringify(mergedCategories));
          localStorage.setItem('last_service_sync', new Date().toISOString());
          
          return {
            mode: 'online',
            prestations: mergedServices,
            categories: mergedCategories
          };
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des prestations:', error);
        return {
          mode: 'error',
          prestations: localServices,
          categories: localCategories,
          error: error.message
        };
      }
      
      return {
        mode: 'online',
        prestations: localServices,
        categories: localCategories
      };
    } catch (erreur) {
      console.error('Erreur lors de la synchronisation des prestations:', erreur);
      throw erreur;
    }
  }

  /**
   * Fusionner les données des prestations
   */
  mergeServiceData(localServices, serverServices) {
    // Vérifier que les arguments sont des tableaux
    if (!Array.isArray(localServices)) localServices = [];
    if (!Array.isArray(serverServices)) serverServices = [];
    
    const mergedServices = [...localServices];
    const serviceMap = new Map();
    
    localServices.forEach(service => {
      if (service && service.id) {
        serviceMap.set(service.id, service);
      }
    });
    
    serverServices.forEach(serverService => {
      if (!serverService || !serverService.id) return;
      
      const localService = serviceMap.get(serverService.id);
      
      if (!localService) {
        mergedServices.push(serverService);
      } else {
        const serverDate = new Date(serverService.last_modified || 0);
        const localDate = new Date(localService.last_modified || 0);
        
        if (serverDate > localDate) {
          const index = mergedServices.findIndex(s => s.id === serverService.id);
          if (index !== -1) {
            mergedServices[index] = serverService;
          }
        } else if (serverDate < localDate) {
          this.pushServiceToServer(localService);
        }
      }
    });
    
    return mergedServices;
  }

  /**
   * Fusionner les données des catégories
   */
  mergeCategoryData(localCategories, serverCategories) {
    // Vérifier que les arguments sont des tableaux
    if (!Array.isArray(localCategories)) localCategories = [];
    if (!Array.isArray(serverCategories)) serverCategories = [];
    
    const mergedCategories = [...localCategories];
    const categoryMap = new Map();
    
    localCategories.forEach(category => {
      if (category && category.id) {
        categoryMap.set(category.id, category);
      }
    });
    
    serverCategories.forEach(serverCategory => {
      if (!serverCategory || !serverCategory.id) return;
      
      const localCategory = categoryMap.get(serverCategory.id);
      
      if (!localCategory) {
        mergedCategories.push(serverCategory);
      } else {
        const serverDate = new Date(serverCategory.last_modified || 0);
        const localDate = new Date(localCategory.last_modified || 0);
        
        if (serverDate > localDate) {
          const index = mergedCategories.findIndex(c => c.id === serverCategory.id);
          if (index !== -1) {
            mergedCategories[index] = serverCategory;
          }
        }
      }
    });
    
    return mergedCategories;
  }

  /**
   * Envoyer une prestation au serveur
   */
  async pushServiceToServer(service) {
    try {
      if (!navigator.onLine) {
        this.addToSyncQueue('service', service);
        return { queued: true };
      }
      
      if (!service || !service.id) {
        console.error('Service invalide ou sans ID');
        return { error: 'Service invalide' };
      }
      
      const method = service.id ? 'PUT' : 'POST';
      const url = service.id ? `/services/${service.id}/` : '/services/';
      
      const response = await this.requeteSecurisee(url, {
        method,
        body: JSON.stringify(service)
      });
      
      return response;
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la prestation au serveur:', error);
      this.addToSyncQueue('service', service);
      throw error;
    }
  }

  /**
   * Synchroniser le stock
   */
  async syncStock() {
    try {
      console.log('Synchronisation du stock...');
      
      // Récupérer les articles locaux
      const localItems = JSON.parse(localStorage.getItem('stock_items') || '[]');
      
      // Si nous sommes hors ligne
      if (!navigator.onLine) {
        return { mode: 'offline', stock: localItems };
      }
      
      // Dernière synchronisation
      const lastStockSync = localStorage.getItem('last_stock_sync') || null;
      
      try {
        // Récupérer du serveur
        const response = await this.requeteSecurisee(`/stock/`, {
          method: 'GET',
          headers: {
            'If-Modified-Since': lastStockSync || ''
          }
        });
        
        // En cas d'API non trouvée
        if (response.notFound) {
          return { mode: 'not_available', stock: localItems };
        }
        
        if (response && response.items) {
          // Assurez-vous que response.items est un tableau
          const serverItems = Array.isArray(response.items) ? response.items : [];
          
          // Fusionner
          const mergedItems = this.mergeStockData(localItems, serverItems);
          
          // Sauvegarder
          localStorage.setItem('stock_items', JSON.stringify(mergedItems));
          localStorage.setItem('last_stock_sync', new Date().toISOString());
          
          return { mode: 'online', stock: mergedItems };
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du stock:', error);
        return { 
          mode: 'error', 
          stock: localItems,
          error: error.message
        };
      }
      
      return { mode: 'online', stock: localItems };
    } catch (erreur) {
      console.error('Erreur lors de la synchronisation du stock:', erreur);
      throw erreur;
    }
  }

  /**
   * Fusionner les données de stock
   */
  mergeStockData(localItems, serverItems) {
    // Vérifier que les arguments sont des tableaux
    if (!Array.isArray(localItems)) localItems = [];
    if (!Array.isArray(serverItems)) serverItems = [];
    
    const mergedItems = [...localItems];
    const itemMap = new Map();
    
    localItems.forEach(item => {
      if (item && item.id) {
        itemMap.set(item.id, item);
      }
    });
    
    serverItems.forEach(serverItem => {
      if (!serverItem || !serverItem.id) return;
      
      const localItem = itemMap.get(serverItem.id);
      
      if (!localItem) {
        mergedItems.push(serverItem);
      } else {
        const serverDate = new Date(serverItem.last_modified || 0);
        const localDate = new Date(localItem.last_modified || 0);
        
        if (serverDate > localDate) {
          const index = mergedItems.findIndex(i => i.id === serverItem.id);
          if (index !== -1) {
            mergedItems[index] = serverItem;
          }
        } else if (serverDate < localDate) {
          this.pushStockItemToServer(localItem);
        }
      }
    });
    
    return mergedItems;
  }

  /**
   * Envoyer un article de stock au serveur
   */
  async pushStockItemToServer(item) {
    try {
      if (!navigator.onLine) {
        this.addToSyncQueue('stock', item);
        return { queued: true };
      }
      
      if (!item || !item.id) {
        console.error('Item invalide ou sans ID');
        return { error: 'Item invalide' };
      }
      
      const method = item.id ? 'PUT' : 'POST';
      const url = item.id ? `/stock/${item.id}/` : '/stock/';
      
      const response = await this.requeteSecurisee(url, {
        method,
        body: JSON.stringify(item)
      });
      
      return response;
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'article au serveur:', error);
      this.addToSyncQueue('stock', item);
      throw error;
    }
  }

  /**
   * Synchroniser les rendez-vous
   */
  async syncAppointments() {
    try {
      console.log('Synchronisation des rendez-vous...');
      
      // Récupérer les paramètres de synchronisation
      const settings = this.getSyncSettings();
      const syncDays = settings.syncAppointmentDays || 90;
      
      // Calculer la date limite pour la synchronisation
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - syncDays);
      
      // Récupérer les rendez-vous locaux
      const allAppointments = JSON.parse(localStorage.getItem('appointments') || '[]');
      const localAppointments = allAppointments.filter(app => 
        new Date(app.start) >= cutoffDate || app.status === 'pending'
      );
      
      // Si nous sommes hors ligne
      if (!navigator.onLine) {
        return { mode: 'offline', appointments: localAppointments };
      }
      
      // Dernière synchronisation
      const lastAppSync = localStorage.getItem('last_app_sync') || null;
      
      try {
        // Récupérer du serveur
        const response = await this.requeteSecurisee(`/appointments/?since=${cutoffDate.toISOString()}`, {
          method: 'GET',
          headers: {
            'If-Modified-Since': lastAppSync || ''
          }
        });
        
        // En cas d'API non trouvée
        if (response.notFound) {
          return { mode: 'not_available', appointments: localAppointments };
        }
        
        if (response) {
          // Récupérer les rendez-vous du serveur, s'assurer que c'est un tableau
          const serverAppointments = Array.isArray(response.appointments) ? response.appointments : [];
          
          // Fusionner
          const mergedAppointments = this.mergeAppointmentData(
            localAppointments, 
            serverAppointments,
            allAppointments.filter(app => new Date(app.start) < cutoffDate && app.status !== 'pending')
          );
          
          // Sauvegarder
          localStorage.setItem('appointments', JSON.stringify(mergedAppointments));
          localStorage.setItem('last_app_sync', new Date().toISOString());
          
          return { 
            mode: 'online', 
            appointments: mergedAppointments.filter(app => 
              new Date(app.start) >= cutoffDate || app.status === 'pending'
            )
          };
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des rendez-vous:', error);
        return { 
          mode: 'error', 
          appointments: localAppointments,
          error: error.message
        };
      }
      
      return { mode: 'online', appointments: localAppointments };
    } catch (erreur) {
      console.error('Erreur lors de la synchronisation des rendez-vous:', erreur);
      throw erreur;
    }
  }

  /**
   * Fusionner les données de rendez-vous
   */
  mergeAppointmentData(localAppointments, serverAppointments, olderAppointments = []) {
    // Vérifier que les arguments sont des tableaux
    if (!Array.isArray(localAppointments)) localAppointments = [];
    if (!Array.isArray(serverAppointments)) serverAppointments = [];
    if (!Array.isArray(olderAppointments)) olderAppointments = [];
    
    let mergedAppointments = [...localAppointments];
    const appointmentMap = new Map();
    
    // Indexer les rendez-vous locaux
    localAppointments.forEach(app => {
      if (app && app.id) {
        appointmentMap.set(app.id, app);
      }
    });
    
    // Traiter les rendez-vous du serveur
    serverAppointments.forEach(serverApp => {
      if (!serverApp || !serverApp.id) return;
      
      const localApp = appointmentMap.get(serverApp.id);
      
      if (!localApp) {
        // Nouveau rendez-vous
        mergedAppointments.push(serverApp);
      } else {
        // Rendez-vous existant
        const serverDate = new Date(serverApp.last_modified || 0);
        const localDate = new Date(localApp.last_modified || 0);
        
        // Détecter les conflits
        if (serverDate > localDate) {
          // Version serveur plus récente
          const index = mergedAppointments.findIndex(a => a.id === serverApp.id);
          if (index !== -1) {
            mergedAppointments[index] = serverApp;
          }
        } else if (serverDate < localDate) {
          // Version locale plus récente
          this.pushAppointmentToServer(localApp);
        } else if (JSON.stringify(localApp) !== JSON.stringify(serverApp)) {
          // Même date mais contenus différents = conflit
          this.handleAppointmentConflict(localApp, serverApp);
        }
      }
    });
    
    // Ajouter les anciens rendez-vous pour maintenir l'historique
    mergedAppointments = [...mergedAppointments, ...olderAppointments];
    
    return mergedAppointments;
  }

  /**
   * Gérer un conflit de rendez-vous
   */
  handleAppointmentConflict(localApp, serverApp) {
    console.log('Conflit détecté pour le rendez-vous:', localApp.id);
    
    // Obtenir la stratégie de résolution des conflits
    const settings = this.getSyncSettings();
    const strategy = settings.conflictResolution || 'askUser';
    
    if (strategy === 'useServer') {
      // Utiliser la version du serveur
      const appointments = JSON.parse(localStorage.getItem('appointments') || '[]');
      const index = appointments.findIndex(a => a.id === serverApp.id);
      
      if (index !== -1) {
        appointments[index] = serverApp;
        localStorage.setItem('appointments', JSON.stringify(appointments));
      }
      
      return serverApp;
    } else if (strategy === 'useLocal') {
      // Utiliser la version locale
      this.pushAppointmentToServer(localApp);
      return localApp;
    } else {
      // Demander à l'utilisateur (plus tard)
      // Pour l'instant, garder les deux versions et notifier
      const conflicts = JSON.parse(localStorage.getItem('sync_conflicts') || '[]');
      
      conflicts.push({
        type: 'appointment',
        localVersion: localApp,
        serverVersion: serverApp,
        timestamp: new Date().toISOString(),
        resolved: false
      });
      
      localStorage.setItem('sync_conflicts', JSON.stringify(conflicts));
      
      // Notifier l'interface utilisateur
      this.notifySyncListeners('syncConflicts', { conflicts });
      
      return localApp; // Conserver la version locale en attendant la résolution
    }
  }

  /**
   * Envoyer un rendez-vous au serveur
   */
  async pushAppointmentToServer(appointment) {
    try {
      if (!navigator.onLine) {
        this.addToSyncQueue('appointment', appointment);
        return { queued: true };
      }
      
      if (!appointment || !appointment.id) {
        console.error('Rendez-vous invalide ou sans ID');
        return { error: 'Rendez-vous invalide' };
      }
      
      const method = appointment.id ? 'PUT' : 'POST';
      const url = appointment.id ? `/appointments/${appointment.id}/` : '/appointments/';
      
      const response = await this.requeteSecurisee(url, {
        method,
        body: JSON.stringify(appointment)
      });
      
      return response;
    } catch (error) {
      console.error('Erreur lors de l\'envoi du rendez-vous au serveur:', error);
      this.addToSyncQueue('appointment', appointment);
      throw error;
    }
  }

  /**
   * Synchroniser les transactions
   */
  async syncTransactions() {
    try {
      console.log('Synchronisation des transactions...');
      
      // Récupérer les paramètres de synchronisation
      const settings = this.getSyncSettings();
      const syncDays = settings.syncTransactionDays || 90;
      
      // Calculer la date limite
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - syncDays);
      
      // Récupérer les transactions locales
      const allTransactions = JSON.parse(localStorage.getItem('transactions') || '[]');
      const localTransactions = allTransactions.filter(
        tx => new Date(tx.date) >= cutoffDate || tx.status === 'pending'
      );
      
      // Si nous sommes hors ligne
      if (!navigator.onLine) {
        return { mode: 'offline', transactions: localTransactions };
      }
      
      // Dernière synchronisation
      const lastTxSync = localStorage.getItem('last_tx_sync') || null;
      
      try {
        // Récupérer du serveur
        const response = await this.requeteSecurisee(`/transactions/?since=${cutoffDate.toISOString()}`, {
          method: 'GET',
          headers: {
            'If-Modified-Since': lastTxSync || ''
          }
        });
        
        // En cas d'API non trouvée
        if (response.notFound) {
          return { mode: 'not_available', transactions: localTransactions };
        }
        
        if (response && response.transactions) {
          // S'assurer que response.transactions est un tableau
          const serverTransactions = Array.isArray(response.transactions) ? response.transactions : [];
          
          // Fusionner
          const mergedTransactions = this.mergeTransactionData(
            localTransactions, 
            serverTransactions,
            allTransactions.filter(tx => 
              new Date(tx.date) < cutoffDate && tx.status !== 'pending'
            )
          );
          
          // Sauvegarder
          localStorage.setItem('transactions', JSON.stringify(mergedTransactions));
          localStorage.setItem('last_tx_sync', new Date().toISOString());
          
          return { 
            mode: 'online', 
            transactions: mergedTransactions.filter(tx => 
              new Date(tx.date) >= cutoffDate || tx.status === 'pending'
            )
          };
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des transactions:', error);
        return { 
          mode: 'error', 
          transactions: localTransactions,
          error: error.message
        };
      }
      
      return { mode: 'online', transactions: localTransactions };
    } catch (erreur) {
      console.error('Erreur lors de la synchronisation des transactions:', erreur);
      throw erreur;
    }
  }

  /**
   * Fusionner les données de transactions
   */
  mergeTransactionData(localTransactions, serverTransactions, olderTransactions = []) {
    // Vérifier que les arguments sont des tableaux
    if (!Array.isArray(localTransactions)) localTransactions = [];
    if (!Array.isArray(serverTransactions)) serverTransactions = [];
    if (!Array.isArray(olderTransactions)) olderTransactions = [];
    
    let mergedTransactions = [...localTransactions];
    const transactionMap = new Map();
    
    localTransactions.forEach(tx => {
      if (tx && tx.id) {
        transactionMap.set(tx.id, tx);
      }
    });
    
    serverTransactions.forEach(serverTx => {
      if (!serverTx || !serverTx.id) return;
      
      const localTx = transactionMap.get(serverTx.id);
      
      if (!localTx) {
        mergedTransactions.push(serverTx);
      } else {
        const serverDate = new Date(serverTx.last_modified || 0);
        const localDate = new Date(localTx.last_modified || 0);
        
        if (serverDate > localDate) {
          const index = mergedTransactions.findIndex(t => t.id === serverTx.id);
          if (index !== -1) {
            mergedTransactions[index] = serverTx;
          }
        } else if (serverDate < localDate) {
          this.pushTransactionToServer(localTx);
        }
      }
    });
    
    // Ajouter les anciennes transactions
    mergedTransactions = [...mergedTransactions, ...olderTransactions];
    
    return mergedTransactions;
  }

  /**
   * Envoyer une transaction au serveur
   */
  async pushTransactionToServer(transaction) {
    try {
      if (!navigator.onLine) {
        this.addToSyncQueue('transaction', transaction);
        return { queued: true };
      }
      
      if (!transaction || !transaction.id) {
        console.error('Transaction invalide ou sans ID');
        return { error: 'Transaction invalide' };
      }
      
      const method = transaction.id ? 'PUT' : 'POST';
      const url = transaction.id ? `/transactions/${transaction.id}/` : '/transactions/';
      
      const response = await this.requeteSecurisee(url, {
        method,
        body: JSON.stringify(transaction)
      });
      
      return response;
    } catch (error) {
      console.error('Erreur lors de l\'envoi de la transaction au serveur:', error);
      this.addToSyncQueue('transaction', transaction);
      throw error;
    }
  }

  /**
   * Synchroniser les paramètres de l'agenda
   */
  async syncAgendaSettings() {
    try {
      console.log('Synchronisation des paramètres de l\'agenda...');
      
      // Récupérer les paramètres locaux
      const localSettings = JSON.parse(localStorage.getItem('agenda_settings') || '{}');
      
      // Si nous sommes hors ligne
      if (!navigator.onLine) {
        return { mode: 'offline', settings: localSettings };
      }
      
      try {
        // Récupérer du serveur
        const response = await this.requeteSecurisee('/settings/agenda/', {
          method: 'GET'
        });
        
        // En cas d'API non trouvée
        if (response.notFound) {
          return { mode: 'not_available', settings: localSettings };
        }
        
        if (response && response.settings) {
          const serverSettings = response.settings;
          
          // Comparer les dates de modification
          const serverDate = new Date(serverSettings.last_modified || 0);
          const localDate = new Date(localSettings.last_modified || 0);
          
          let mergedSettings;
          
          if (serverDate > localDate) {
            // Utiliser les paramètres du serveur
            mergedSettings = serverSettings;
          } else if (localDate > serverDate) {
            // Utiliser les paramètres locaux et les envoyer au serveur
            mergedSettings = localSettings;
            await this.requeteSecurisee('/settings/agenda/', {
              method: 'POST',
              body: JSON.stringify(localSettings)
            });
          } else {
            // Même date, utiliser les paramètres du serveur
            mergedSettings = serverSettings;
          }
          
          // Sauvegarder
          localStorage.setItem('agenda_settings', JSON.stringify(mergedSettings));
          
          return { mode: 'online', settings: mergedSettings };
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des paramètres d\'agenda:', error);
        return { 
          mode: 'error', 
          settings: localSettings,
          error: error.message
        };
      }
      
      return { mode: 'online', settings: localSettings };
    } catch (erreur) {
      console.error('Erreur lors de la synchronisation des paramètres d\'agenda:', erreur);
      throw erreur;
    }
  }

  /**
   * Synchroniser les paramètres de caisse
   */
  async syncCaisseSettings() {
    try {
      console.log('Synchronisation des paramètres de caisse...');
      
      // Récupérer les paramètres locaux
      const localSettings = JSON.parse(localStorage.getItem('caisse_settings') || '{}');
      
      // Si nous sommes hors ligne
      if (!navigator.onLine) {
        return { mode: 'offline', settings: localSettings };
      }
      
      try {
        // Récupérer du serveur
        const response = await this.requeteSecurisee('/settings/caisse/', {
          method: 'GET'
        });
        
        // En cas d'API non trouvée
        if (response.notFound) {
          return { mode: 'not_available', settings: localSettings };
        }
        
        if (response && response.settings) {
          const serverSettings = response.settings;
          
          // Comparer les dates de modification
          const serverDate = new Date(serverSettings.last_modified || 0);
          const localDate = new Date(localSettings.last_modified || 0);
          
          let mergedSettings;
          
          if (serverDate > localDate) {
            // Utiliser les paramètres du serveur
            mergedSettings = serverSettings;
          } else if (localDate > serverDate) {
            // Utiliser les paramètres locaux et les envoyer au serveur
            mergedSettings = localSettings;
            await this.requeteSecurisee('/settings/caisse/', {
              method: 'POST',
              body: JSON.stringify(localSettings)
            });
          } else {
            // Même date, utiliser les paramètres du serveur
            mergedSettings = serverSettings;
          }
          
          // Sauvegarder
          localStorage.setItem('caisse_settings', JSON.stringify(mergedSettings));
          
          return { mode: 'online', settings: mergedSettings };
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des paramètres de caisse:', error);
        return { 
          mode: 'error', 
          settings: localSettings,
          error: error.message
        };
      }
      
      return { mode: 'online', settings: localSettings };
    } catch (erreur) {
      console.error('Erreur lors de la synchronisation des paramètres de caisse:', erreur);
      throw erreur;
    }
  }

  /**
   * Obtenir le statut de synchronisation
   */
  async getSyncStatus() {
    try {
      const lastSync = localStorage.getItem('last_sync') || null;
      const syncQueue = JSON.parse(localStorage.getItem('sync_queue') || '[]');
      const conflicts = JSON.parse(localStorage.getItem('sync_conflicts') || '[]');
      const backups = JSON.parse(localStorage.getItem('backups') || '[]');
      const settings = this.getSyncSettings();
      
      return {
        lastSync,
        queueLength: syncQueue.length,
        conflicts: conflicts.filter(c => !c.resolved),
        backups,
        settings,
        isOnline: navigator.onLine
      };
    } catch (erreur) {
      console.error('Erreur lors de la récupération du statut de synchronisation:', erreur);
      throw erreur;
    }
  }

  /**
   * Résoudre un conflit
   */
  async resolveConflict(conflictId, resolution) {
    try {
      const conflicts = JSON.parse(localStorage.getItem('sync_conflicts') || '[]');
      const conflictIndex = conflicts.findIndex(c => c.id === conflictId);
      
      if (conflictIndex === -1) {
        throw new Error('Conflit non trouvé');
      }
      
      const conflict = conflicts[conflictIndex];
      
      // Appliquer la résolution
      if (resolution === 'useLocal') {
        // Utiliser la version locale
        switch (conflict.type) {
          case 'appointment':
            await this.pushAppointmentToServer(conflict.localVersion);
            break;
          case 'transaction':
            await this.pushTransactionToServer(conflict.localVersion);
            break;
          case 'client':
            await this.pushClientToServer(conflict.localVersion);
            break;
          case 'service':
            await this.pushServiceToServer(conflict.localVersion);
            break;
          case 'stock':
            await this.pushStockItemToServer(conflict.localVersion);
            break;
        }
      } else if (resolution === 'useServer') {
        // Utiliser la version du serveur
        switch (conflict.type) {
          case 'appointment':
            {
              const appointments = JSON.parse(localStorage.getItem('appointments') || '[]');
              const index = appointments.findIndex(a => a.id === conflict.serverVersion.id);
              if (index !== -1) {
                appointments[index] = conflict.serverVersion;
                localStorage.setItem('appointments', JSON.stringify(appointments));
              }
            }
            break;
          case 'transaction':
            {
              const transactions = JSON.parse(localStorage.getItem('transactions') || '[]');
              const index = transactions.findIndex(t => t.id === conflict.serverVersion.id);
              if (index !== -1) {
                transactions[index] = conflict.serverVersion;
                localStorage.setItem('transactions', JSON.stringify(transactions));
              }
            }
            break;
          case 'client':
            {
              const clients = JSON.parse(localStorage.getItem('clients') || '[]');
              const index = clients.findIndex(c => c.id === conflict.serverVersion.id);
              if (index !== -1) {
                clients[index] = conflict.serverVersion;
                localStorage.setItem('clients', JSON.stringify(clients));
              }
            }
            break;
          case 'service':
            {
              const services = JSON.parse(localStorage.getItem('prestations') || '[]');
              const index = services.findIndex(s => s.id === conflict.serverVersion.id);
              if (index !== -1) {
                services[index] = conflict.serverVersion;
                localStorage.setItem('prestations', JSON.stringify(services));
              }
            }
            break;
          case 'stock':
            {
              const items = JSON.parse(localStorage.getItem('stock_items') || '[]');
              const index = items.findIndex(i => i.id === conflict.serverVersion.id);
              if (index !== -1) {
                items[index] = conflict.serverVersion;
                localStorage.setItem('stock_items', JSON.stringify(items));
              }
            }
            break;
        }
      }
      
      // Marquer le conflit comme résolu
      conflicts[conflictIndex].resolved = true;
      conflicts[conflictIndex].resolution = resolution;
      conflicts[conflictIndex].resolvedAt = new Date().toISOString();
      
      localStorage.setItem('sync_conflicts', JSON.stringify(conflicts));
      
      // Notifier l'interface utilisateur
      this.notifySyncListeners('conflictResolved', { 
        conflict: conflicts[conflictIndex],
        remainingConflicts: conflicts.filter(c => !c.resolved)
      });
      
      return { success: true, conflict: conflicts[conflictIndex] };
    } catch (erreur) {
      console.error('Erreur lors de la résolution du conflit:', erreur);
      throw erreur;
    }
  }

  /**
   * Créer une sauvegarde
   */
  async createBackup(name = '') {
    try {
      console.log('Création d\'une sauvegarde...');
      
      // Collecter toutes les données importantes
      const backupData = {
        clients: JSON.parse(localStorage.getItem('clients') || '[]'),
        appointments: JSON.parse(localStorage.getItem('appointments') || '[]'),
        transactions: JSON.parse(localStorage.getItem('transactions') || '[]'),
        prestations: JSON.parse(localStorage.getItem('prestations') || '[]'),
        categories: JSON.parse(localStorage.getItem('categories') || '[]'),
        stock_items: JSON.parse(localStorage.getItem('stock_items') || '[]'),
        agenda_settings: JSON.parse(localStorage.getItem('agenda_settings') || '{}'),
        caisse_settings: JSON.parse(localStorage.getItem('caisse_settings') || '{}'),
        users: JSON.parse(localStorage.getItem('users') || '[]')
      };
      
      // Créer la sauvegarde
      const backup = {
        id: 'backup_' + Date.now(),
        name: name || `Sauvegarde du ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
        date: new Date().toISOString(),
        data: backupData,
        size: JSON.stringify(backupData).length,
        type: 'manual'
      };
      
      // Stocker dans le localStorage
      const backups = JSON.parse(localStorage.getItem('backups') || '[]');
      backups.push(backup);
      
      // Limiter à 10 sauvegardes
      if (backups.length > 10) {
        backups.sort((a, b) => new Date(b.date) - new Date(a.date));
        backups.splice(10);
      }
      
      localStorage.setItem('backups', JSON.stringify(backups));
      
      // Envoyer au serveur si en ligne
      if (navigator.onLine) {
        try {
          await this.requeteSecurisee('/backups/', {
            method: 'POST',
            body: JSON.stringify({
              id: backup.id,
              name: backup.name,
              date: backup.date,
              metadata: {
                type: backup.type,
                size: backup.size,
                itemCounts: {
                  clients: backupData.clients.length,
                  appointments: backupData.appointments.length,
                  transactions: backupData.transactions.length,
                  services: backupData.prestations.length,
                  categories: backupData.categories.length,
                  stock: backupData.stock_items.length
                }
              }
            })
          });
        } catch (error) {
          console.error('Erreur lors de l\'envoi de la sauvegarde au serveur:', error);
          // Continuer malgré l'erreur - la sauvegarde locale est réussie
        }
      }
      
      // Notifier l'interface utilisateur
      this.notifySyncListeners('backupCreated', { backup });
      
      return { success: true, backup };
    } catch (erreur) {
      console.error('Erreur lors de la création de la sauvegarde:', erreur);
      throw erreur;
    }
  }

  /**
   * Restaurer depuis une sauvegarde
   */
  async restoreFromBackup(backupId) {
    try {
      console.log(`Restauration depuis la sauvegarde ${backupId}...`);
      
      // Récupérer les sauvegardes
      const backups = JSON.parse(localStorage.getItem('backups') || '[]');
      const backup = backups.find(b => b.id === backupId);
      
      if (!backup) {
        throw new Error('Sauvegarde non trouvée');
      }
      
      // Créer une sauvegarde avant la restauration
      await this.createBackup('Sauvegarde automatique avant restauration');
      
      // Restaurer les données
      if (backup.data.clients) {
        localStorage.setItem('clients', JSON.stringify(backup.data.clients));
      }
      
      if (backup.data.appointments) {
        localStorage.setItem('appointments', JSON.stringify(backup.data.appointments));
      }
      
      if (backup.data.transactions) {
        localStorage.setItem('transactions', JSON.stringify(backup.data.transactions));
      }
      
      if (backup.data.prestations) {
        localStorage.setItem('prestations', JSON.stringify(backup.data.prestations));
      }
      
      if (backup.data.categories) {
        localStorage.setItem('categories', JSON.stringify(backup.data.categories));
      }
      
      if (backup.data.stock_items) {
        localStorage.setItem('stock_items', JSON.stringify(backup.data.stock_items));
      }
      
      if (backup.data.agenda_settings) {
        localStorage.setItem('agenda_settings', JSON.stringify(backup.data.agenda_settings));
      }
      
      if (backup.data.caisse_settings) {
        localStorage.setItem('caisse_settings', JSON.stringify(backup.data.caisse_settings));
      }
      
      // Notifier l'interface utilisateur
      this.notifySyncListeners('backupRestored', { backup });
      
      // Planifier une synchronisation complète
      setTimeout(() => {
        this.syncAllData().catch(err => {
          console.error('Erreur lors de la synchronisation après restauration:', err);
        });
      }, 2000);
      
      return { success: true };
    } catch (erreur) {
      console.error('Erreur lors de la restauration depuis la sauvegarde:', erreur);
      throw erreur;
    }
  }

  /**
   * Mettre à jour le profil utilisateur
   * @param {Object} userData - Données de l'utilisateur à mettre à jour
   * @returns {Promise} - Promesse de synchronisation
   */
  async updateUserProfile(userData) {
    try {
      console.log('Synchronisation du profil utilisateur...');
      
      if (!navigator.onLine) {
        // Mettre à jour localement en attendant une connexion
        const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        const updatedUser = { ...currentUser, ...userData };
        localStorage.setItem('currentUser', JSON.stringify(updatedUser));
        
        // Mettre à jour la liste des utilisateurs
        const users = JSON.parse(localStorage.getItem('users') || '[]');
        const userIndex = users.findIndex(u => u.id === userData.id);
        
        if (userIndex !== -1) {
          users[userIndex] = { ...users[userIndex], ...userData };
          localStorage.setItem('users', JSON.stringify(users));
        }
        
        // Ajouter à la file d'attente
        this.addToSyncQueue('user', userData);
        
        return { success: true, mode: 'offline' };
      }
      
      // Requête de synchronisation du profil
      const result = await this.requeteSecurisee('/users/update-profile/', {
        method: 'POST',
        body: JSON.stringify(userData)
      });
      
      // Vérifier si la synchronisation a réussi
      if (result.success) {
        // Mise à jour du localStorage
        const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        const updatedUser = { ...currentUser, ...userData };
        localStorage.setItem('currentUser', JSON.stringify(updatedUser));
        
        // Mettre à jour la liste des utilisateurs
        const users = JSON.parse(localStorage.getItem('users') || '[]');
        const userIndex = users.findIndex(u => u.id === userData.id);
        
        if (userIndex !== -1) {
          users[userIndex] = { ...users[userIndex], ...userData };
          localStorage.setItem('users', JSON.stringify(users));
        }
        
        return { ...result, mode: 'online' };
      }
      
      throw new Error(result.message || 'Erreur lors de la mise à jour du profil');
    } catch (erreur) {
      console.error('Erreur lors de la synchronisation du profil:', erreur);
      throw erreur;
    }
  }

  /**
   * Synchronisation rapide avant fermeture
   */
  async quickSync() {
    console.log('Exécution de la synchronisation rapide...');
    
    if (!navigator.onLine) {
      console.log('Mode hors ligne, synchronisation rapide ignorée');
      return { success: false, mode: 'offline' };
    }
    
    // Si une synchronisation complète est en cours, ne pas en démarrer une autre
    if (this.isSyncing) {
      console.log('Une synchronisation est déjà en cours');
      return { success: false, mode: 'busy' };
    }
    
    try {
      // Traiter uniquement la file d'attente
      await this.processQueuedSyncs();
      
      return { success: true, mode: 'online' };
    } catch (error) {
      console.error('Erreur lors de la synchronisation rapide:', error);
      return { success: false, error: error.message };
    }
  }
}

// Créer une instance unique du service
export const DataSyncService = new DataSyncServiceClass();

// Exporter par défaut pour faciliter l'importation
export default DataSyncService;