import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import "./Agenda.css";

export const DayView = ({
  currentDate,
  settings = {}, // Valeur par défaut si settings est undefined
  appointments,
  onAppointmentClick,
  onTimeSlotClick,
}) => {
  console.log("---DayView: RENDU COMPLET DU COMPOSANT---");
  console.log("DayView - Rendez-vous reçus:", appointments);
  console.log("DayView - Date actuelle:", currentDate);
  console.log("DayView - Settings:", settings);

  const [selectedSlots, setSelectedSlots] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [draggedAppointment, setDraggedAppointment] = useState(null);
  const [dragOverInfo, setDragOverInfo] = useState(null);
  const [selectionDuration, setSelectionDuration] = useState(null);
  const startSlotRef = useRef(null);
  const weekGridRef = useRef(null);

  // Fonction pour formater une date en YYYY-MM-DD
  const formatDateForAttribute = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const formatted = `${yyyy}-${mm}-${dd}`;
    console.log(
      `DayView - formatDateForAttribute: date=${date} → ${formatted}`
    );
    return formatted;
  };

  // Fonction utilitaire améliorée pour normaliser une date
  const normalizeDateFormat = (dateString) => {
    console.log(`DayView - normalizeDateFormat: input=${dateString}`);

    // Vérification des entrées null, undefined ou vides
    if (!dateString) {
      console.log("DayView - normalizeDateFormat: dateString vide ou null");
      return "";
    }

    // Si la date est déjà au format YYYY-MM-DD, la retourner directement
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      console.log(
        `DayView - normalizeDateFormat: déjà au bon format ${dateString}`
      );
      return dateString;
    }

    // Sinon, essayer de la convertir en gérant les erreurs
    try {
      // Vérifier si c'est une date valide
      if (isNaN(Date.parse(dateString))) {
        console.error(
          `DayView - normalizeDateFormat: date invalide ${dateString}`
        );
        return "";
      }

      const date = new Date(dateString);
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const dd = String(date.getDate()).padStart(2, "0");
      const result = `${yyyy}-${mm}-${dd}`;
      console.log(
        `DayView - normalizeDateFormat: converti ${dateString} → ${result}`
      );
      return result;
    } catch (e) {
      console.error("DayView - Erreur de normalisation date:", e);
      return "";
    }
  };

  // Fonction utilitaire pour ajuster une heure "HH:mm:ss" -> "HH:mm"
  const normalizeTime = (timeStr) => timeStr?.slice(0, 5);

  // Fonction utilitaire pour comparer deux dates après normalisation
  const compareDates = (date1, date2) => {
    const normalized1 = normalizeDateFormat(date1);
    const normalized2 = normalizeDateFormat(date2);
    return normalized1 === normalized2;
  };

  // Vérifier si une date est aujourd'hui
  const isToday = (date) => {
    const today = new Date();
    const result =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
    console.log(`DayView - isToday: ${date} → ${result}`);
    return result;
  };

  // Vérifier si le jour est fermé selon les paramètres - CORRECTION AJOUTÉE ICI
  const isClosedDay = (date) => {
    const dayOfWeek = date.getDay(); // 0-6 (dimanche-samedi)
    // Vérifier que closedDays existe avant d'utiliser includes
    const result =
      settings && settings.closedDays
        ? settings.closedDays.includes(dayOfWeek)
        : false;
    console.log(
      `DayView - isClosedDay: ${date} (jour ${dayOfWeek}) → ${result}`
    );
    return result;
  };

  // Vérifier si l'heure actuelle est visible
  const isCurrentTimeVisible = () => {
    const now = new Date();
    const currentHour = now.getHours();

    // Vérifier si l'heure actuelle est dans la plage d'heures affichée
    const startHour = settings.startHour || 9; // Valeur par défaut si undefined
    const endHour = settings.endHour || 19; // Valeur par défaut si undefined

    const hourInRange = currentHour >= startHour && currentHour <= endHour;
    const isTodayView = isToday(currentDate);

    const result = hourInRange && isTodayView;
    console.log(
      `DayView - isCurrentTimeVisible: heure actuelle=${currentHour}, plage=[${startHour},${endHour}], isToday=${isTodayView} → ${result}`
    );
    return result;
  };

  // Calculer la position de l'indicateur d'heure actuelle
  const calculateTimeIndicatorPosition = () => {
    if (!isCurrentTimeVisible()) {
      console.log(
        "DayView - calculateTimeIndicatorPosition: L'heure actuelle n'est pas visible"
      );
      return null;
    }

    const now = new Date();
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    const startHour = settings.startHour || 9; // Valeur par défaut
    const endHour = settings.endHour || 19; // Valeur par défaut

    if (currentHour >= startHour && currentHour <= endHour) {
      const minutesSinceStart = (currentHour - startHour) * 60 + currentMinute;
      const totalMinutes = (endHour - startHour) * 60;
      const percentage = (minutesSinceStart / totalMinutes) * 100;
      console.log(`DayView - calculateTimeIndicatorPosition: ${percentage}%`);
      return `${percentage}%`;
    }

    console.log(
      "DayView - calculateTimeIndicatorPosition: Heure actuelle hors de la plage"
    );
    return null;
  };

  // Vérifier si un créneau horaire est dans une période de fermeture - CORRECTION AJOUTÉE ICI
  const isClosedTimeSlot = (timeSlot) => {
    if (isClosedDay(currentDate)) {
      console.log(
        `DayView - isClosedTimeSlot: ${timeSlot} → vrai (jour fermé)`
      );
      return true;
    }

    // S'assurer que settings existe
    if (!settings) {
      console.log(
        `DayView - isClosedTimeSlot: ${timeSlot} → faux (pas de settings)`
      );
      return false;
    }

    const dayOfWeek = currentDate.getDay().toString(); // Convertir en chaîne
    const closingHours = settings.closingHours || {};
    const dayClosingHours = closingHours[dayOfWeek] || [];

    if (dayClosingHours.length === 0) {
      console.log(
        `DayView - isClosedTimeSlot: ${timeSlot} → faux (pas d'heures de fermeture)`
      );
      return false;
    }

    // Convertir le créneau horaire en minutes pour faciliter la comparaison
    const [hours, minutes] = timeSlot.split(":").map(Number);
    const timeInMinutes = hours * 60 + minutes;

    // Vérifier si le créneau est dans une plage de fermeture
    const isClosed = dayClosingHours.some((range) => {
      if (!range || !range.start || !range.end) return false;

      const [startHours, startMinutes] = range.start.split(":").map(Number);
      const [endHours, endMinutes] = range.end.split(":").map(Number);

      const startInMinutes = startHours * 60 + startMinutes;
      const endInMinutes = endHours * 60 + endMinutes;

      return timeInMinutes >= startInMinutes && timeInMinutes < endInMinutes;
    });

    console.log(
      `DayView - isClosedTimeSlot: ${timeSlot} → ${isClosed} (vérification des plages)`
    );
    return isClosed;
  };

  // Fonction pour convertir un créneau horaire en minutes depuis minuit
  const timeToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  // Fonction pour convertir des minutes depuis minuit en créneau horaire
  const minutesToTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  };

  // Générer les créneaux horaires avec pas standard - VALEURS PAR DÉFAUT AJOUTÉES
  const generateTimeSlots = () => {
    const startHour = settings.startHour || 9; // Valeur par défaut
    const endHour = settings.endHour || 19; // Valeur par défaut
    const timeStep = settings.timeStep || 30; // Valeur par défaut
    const slotsPerHour = 60 / timeStep;

    console.log(
      `DayView - generateTimeSlots: plage=[${startHour},${endHour}], pas=${timeStep}`
    );

    const slots = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      for (let slot = 0; slot < slotsPerHour; slot++) {
        const minutes = slot * timeStep;
        const timeString = `${hour.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        slots.push(timeString);
      }
    }

    console.log(
      `DayView - generateTimeSlots: ${slots.length} créneaux générés`
    );
    return slots;
  };

  // Obtenir les créneaux horaires avec un pas plus fin pour la sélection
  const getSelectionTimeSlots = () => {
    const startHour = settings.startHour || 9; // Valeur par défaut
    const endHour = settings.endHour || 19; // Valeur par défaut
    // Assurer un pas de 5 minutes pour la sélection
    const slotsPerHour = 12; // 60 / 5 = 12 créneaux par heure

    console.log(
      `DayView - getSelectionTimeSlots: plage=[${startHour},${endHour}], pas=5`
    );

    const slots = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      for (let slot = 0; slot < slotsPerHour; slot++) {
        const minutes = slot * 5;
        const timeString = `${hour.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`;
        slots.push(timeString);
      }
    }

    console.log(
      `DayView - getSelectionTimeSlots: ${slots.length} créneaux fins générés`
    );
    return slots;
  };

  // Mise en cache des créneaux horaires
  const timeSlots = useMemo(
    () => generateTimeSlots(),
    [settings.startHour, settings.endHour, settings.timeStep]
  );
  const selectionTimeSlots = useMemo(
    () => getSelectionTimeSlots(),
    [settings.startHour, settings.endHour]
  );

  // Création d'une Map pour accélérer l'accès aux créneaux horaires
  const createTimeSlotMap = useCallback(() => {
    console.log("DayView - createTimeSlotMap: création de la map des créneaux");
    const map = new Map();
    let slotMinutes;

    for (const slot of selectionTimeSlots) {
      slotMinutes = timeToMinutes(slot);
      map.set(slotMinutes, slot);
    }

    console.log(`DayView - createTimeSlotMap: ${map.size} créneaux indexés`);
    return map;
  }, [selectionTimeSlots]);

  const timeSlotMap = useMemo(() => createTimeSlotMap(), [createTimeSlotMap]);

  // Optimisation pour la vérification des créneaux sélectionnés
  const selectedSlotsSet = useMemo(() => {
    const set = new Set();
    for (const slot of selectedSlots) {
      set.add(slot);
    }
    console.log(
      `DayView - selectedSlotsSet: ${set.size} créneaux sélectionnés`
    );
    return set;
  }, [selectedSlots]);

  const isSlotSelected = useCallback(
    (timeSlot) => {
      return selectedSlotsSet.has(timeSlot);
    },
    [selectedSlotsSet]
  );

  // NOUVEAU: Cache des rendez-vous pour la date actuelle
  const cachedDayAppointments = useMemo(() => {
    const formattedDate = formatDateForAttribute(currentDate);
    console.log(
      `DayView - Initialisation du cache pour le jour ${formattedDate}`
    );

    // Filtrer avec le nouveau système de normalisation
    const filtered = appointments.filter((appointment) => {
      const normalizedAppDate = normalizeDateFormat(appointment.date);
      const normalizedCurrentDate = formattedDate;
      const matches = normalizedAppDate === normalizedCurrentDate;

      if (matches) {
        console.log(
          `DayView - Ajout au cache: RDV ${appointment.id}, date=${appointment.date}`
        );
      }

      return matches;
    });

    console.log(
      `DayView - Cache initialisé avec ${filtered.length} rendez-vous pour ${formattedDate}`
    );
    return filtered;
  }, [appointments, currentDate]);

  // Gestionnaire pour commencer la sélection
  const handleMouseDown = (e, timeSlot) => {
    e.preventDefault();
    console.log(`DayView - handleMouseDown: début de sélection à ${timeSlot}`);

    // Vérifier si on clique sur un rendez-vous ou si le créneau est fermé
    if (e.target.closest(".appointment") || isClosedTimeSlot(timeSlot)) {
      console.log(
        `DayView - handleMouseDown: clic sur RDV ou créneau fermé, annulation`
      );
      return;
    }

    setIsSelecting(true);
    startSlotRef.current = timeSlot;
    setSelectedSlots([timeSlot]);
    setSelectionDuration(5); // Durée initiale de 5 minutes
    console.log(`DayView - handleMouseDown: sélection démarrée`);
  };

  // Gestionnaire pour continuer la sélection
  const handleMouseOver = useCallback(
    (timeSlot) => {
      if (!isSelecting || !startSlotRef.current) {
        return;
      }

      console.log(`DayView - handleMouseOver: survol de ${timeSlot}`);

      // Si le créneau est fermé, ne pas le sélectionner
      if (isClosedTimeSlot(timeSlot)) {
        console.log(`DayView - handleMouseOver: créneau fermé, ignoré`);
        return;
      }

      // Calculer l'intervalle de temps
      const startMinutes = timeToMinutes(startSlotRef.current);
      const currentMinutes = timeToMinutes(timeSlot);

      const minMinutes = Math.min(startMinutes, currentMinutes);
      const maxMinutes = Math.max(startMinutes, currentMinutes);

      console.log(
        `DayView - handleMouseOver: plage=${minMinutes}-${maxMinutes} (${
          maxMinutes - minMinutes
        } min)`
      );

      // Optimisation: créer un ensemble de minutes pour les créneaux fermés
      const closedMinutes = new Set();

      for (let min = minMinutes; min <= maxMinutes; min += 5) {
        const slot = timeSlotMap.get(min);
        if (slot && isClosedTimeSlot(slot)) {
          closedMinutes.add(min);
        }
      }

      console.log(
        `DayView - handleMouseOver: ${closedMinutes.size} créneaux fermés dans la plage`
      );

      // Filtrer les minutes pour ne garder que les créneaux valides
      const selectedSlots = [];

      for (let min = minMinutes; min <= maxMinutes; min += 5) {
        if (!closedMinutes.has(min)) {
          const slot = timeSlotMap.get(min);
          if (slot) {
            selectedSlots.push(slot);
          }
        }
      }

      // Calculer la durée en minutes
      if (selectedSlots.length > 0) {
        // Toujours arrondir à 5 minutes près
        const duration = Math.ceil((selectedSlots.length * 5) / 5) * 5;
        setSelectionDuration(duration);
        console.log(`DayView - handleMouseOver: durée=${duration} min`);
      }

      setSelectedSlots(selectedSlots);
      console.log(
        `DayView - handleMouseOver: ${selectedSlots.length} créneaux sélectionnés`
      );
    },
    [isSelecting, timeSlotMap]
  );

  // Gestionnaire pour terminer la sélection
  const handleMouseUp = () => {
    if (!isSelecting || selectedSlots.length === 0) {
      console.log(`DayView - handleMouseUp: pas de sélection en cours, ignoré`);
      return;
    }

    console.log(`DayView - handleMouseUp: fin de sélection`);
    setIsSelecting(false);
    setSelectionDuration(null);

    // Format date for passing to modal
    const formattedDate = formatDateForAttribute(currentDate);
    const startTime = selectedSlots[0];

    // Calculer la durée en minutes (différence entre le premier et le dernier créneau)
    const startMinutes = timeToMinutes(selectedSlots[0]);
    const lastSlotTime = selectedSlots[selectedSlots.length - 1];
    const endMinutes = timeToMinutes(lastSlotTime) + 5; // Ajouter 5 minutes pour la fin

    // Durée exacte en multiple de 5 minutes
    const duration = Math.ceil((endMinutes - startMinutes) / 5) * 5;

    console.log(
      `DayView - handleMouseUp: ouverture modal avec date=${formattedDate}, début=${startTime}, durée=${duration}`
    );
    // Open appointment modal with selected data
    onTimeSlotClick(formattedDate, startTime, duration);

    // Clear selection
    setSelectedSlots([]);
    startSlotRef.current = null;
    console.log(`DayView - handleMouseUp: sélection effacée`);
  };

  // Gestion du glisser-déposer pour les rendez-vous
  const handleDragStart = (e, appointment) => {
    console.log(
      `DayView - handleDragStart: début glisser-déposer du RDV ${appointment.id}`
    );
    setDraggedAppointment(appointment);

    // Ajouter une classe pour le style
    if (e.target.classList) {
      e.target.classList.add("appointment-dragging");
    }

    // Définir les données de transfert pour le drag & drop HTML5
    if (e.dataTransfer) {
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/plain", appointment.id);
    }
  };

  const handleDragOver = (e, timeSlot) => {
    e.preventDefault();

    if (!draggedAppointment) return;

    // Mettre à jour l'information de survol
    setDragOverInfo(timeSlot);
    // console.log(`DayView - handleDragOver: survol du créneau ${timeSlot}`); // Commenté car trop verbeux
  };

  const handleDrop = (e, timeSlot) => {
    e.preventDefault();

    if (!draggedAppointment) {
      console.log(`DayView - handleDrop: pas de RDV en cours de déplacement`);
      return;
    }

    console.log(
      `DayView - handleDrop: déposer le RDV ${draggedAppointment.id} sur ${timeSlot}`
    );

    // Obtenir la date du jour affiché
    const formattedDate = formatDateForAttribute(currentDate);

    // Mettre à jour l'heure de début du rendez-vous
    const updatedAppointment = {
      ...draggedAppointment,
      date: formattedDate,
      start: timeSlot,
    };

    console.log(
      `DayView - handleDrop: mise à jour du RDV - nouvelle date=${formattedDate}, début=${timeSlot}`
    );

    // Éditer le rendez-vous (ouvre la modal pour confirmer)
    onAppointmentClick(draggedAppointment.id, updatedAppointment);

    // Réinitialiser l'état
    setDraggedAppointment(null);
    setDragOverInfo(null);
    console.log(`DayView - handleDrop: états réinitialisés`);
  };

  const handleDragEnd = (e) => {
    console.log(`DayView - handleDragEnd: fin du glisser-déposer`);
    // Nettoyer les styles
    if (e.target.classList) {
      e.target.classList.remove("appointment-dragging");
    }

    setDraggedAppointment(null);
    setDragOverInfo(null);
  };

  // Effet pour nettoyer la sélection en cas de clic en dehors de la grille
  useEffect(() => {
    const handleGlobalMouseUp = () => {
      if (isSelecting) {
        console.log(`DayView - handleGlobalMouseUp: nettoyage de la sélection`);
        setIsSelecting(false);
        setSelectedSlots([]);
        startSlotRef.current = null;
        setSelectionDuration(null);
      }

      // Réinitialiser l'état de glisser-déposer
      if (draggedAppointment) {
        console.log(
          `DayView - handleGlobalMouseUp: nettoyage du glisser-déposer`
        );
        setDraggedAppointment(null);
        setDragOverInfo(null);
      }
    };

    console.log(`DayView - useEffect: ajout du gestionnaire global mouseup`);
    document.addEventListener("mouseup", handleGlobalMouseUp);

    return () => {
      console.log(
        `DayView - useEffect cleanup: suppression du gestionnaire global mouseup`
      );
      document.removeEventListener("mouseup", handleGlobalMouseUp);
    };
  }, [isSelecting, draggedAppointment]);

  // Obtenir les rendez-vous pour le jour actuel - OPTIMISÉ AVEC CACHE
  const getDayAppointments = () => {
    const formattedDate = formatDateForAttribute(currentDate);
    console.log(
      `DayView - getDayAppointments: date recherchée=${formattedDate}`
    );

    // Utiliser directement le cache des rendez-vous
    console.log(
      `DayView - getDayAppointments: ${cachedDayAppointments.length} rendez-vous trouvés pour le jour ${formattedDate}`
    );

    return cachedDayAppointments;
  };

  // Obtenir les rendez-vous pour une heure spécifique - MODIFIÉ AVEC normalizeTime
  const getAppointmentsForTime = (timeSlot) => {
    const dayAppointments = getDayAppointments();
    console.log(
      `DayView - getAppointmentsForTime: recherche pour le créneau ${timeSlot} (${dayAppointments.length} RDV ce jour)`
    );
    const overlappingAppointments = dayAppointments.filter((appointment) => {
      const appointmentStart = normalizeTime(appointment.start);
      if (appointmentStart === timeSlot) {
        console.log(
          `DayView - Créneau ${timeSlot}: RDV ${appointment.id} commence exactement à ce créneau`
        );
        return true;
      }
      const appointmentStartTime = timeToMinutes(appointmentStart);
      const appointmentEndTime =
        appointmentStartTime + parseInt(appointment.duration);
      const slotTime = timeToMinutes(timeSlot);
      const isOverlapping =
        slotTime > appointmentStartTime && slotTime < appointmentEndTime;
      if (isOverlapping) {
        console.log(
          `DayView - Créneau ${timeSlot}: RDV ${appointment.id} chevauche ce créneau (${appointment.start} + ${appointment.duration}min)`
        );
      }
      return isOverlapping;
    });
    console.log(
      `DayView - getAppointmentsForTime: ${overlappingAppointments.length} RDV chevauchent le créneau ${timeSlot}`
    );
    if (overlappingAppointments.length === 0) return [];
    const appsStartingAtTimeSlot = overlappingAppointments.filter(
      (app) => normalizeTime(app.start) === timeSlot
    );
    console.log(
      `DayView - getAppointmentsForTime: ${appsStartingAtTimeSlot.length} RDV commencent exactement au créneau ${timeSlot}`
    );
    if (appsStartingAtTimeSlot.length === 0) return [];
    const overlapGroups = [];
    let currentGroup = [];
    const sortedAppointments = [...dayAppointments].sort(
      (a, b) =>
        timeToMinutes(normalizeTime(a.start)) -
        timeToMinutes(normalizeTime(b.start))
    );
    for (const appointment of sortedAppointments) {
      const startTime = timeToMinutes(normalizeTime(appointment.start));
      const endTime = startTime + parseInt(appointment.duration);
      const overlapsWithCurrentGroup = currentGroup.some((app) => {
        const groupStart = timeToMinutes(normalizeTime(app.start));
        const groupEnd = groupStart + parseInt(app.duration);
        return startTime < groupEnd && endTime > groupStart;
      });
      if (overlapsWithCurrentGroup) {
        currentGroup.push(appointment);
      } else {
        if (currentGroup.length > 0) {
          overlapGroups.push([...currentGroup]);
        }
        currentGroup = [appointment];
      }
    }
    if (currentGroup.length > 0) {
      overlapGroups.push(currentGroup);
    }
    console.log(
      `DayView - getAppointmentsForTime: ${overlapGroups.length} groupes de chevauchement identifiés`
    );
    let appointmentGroup = [];
    let totalInGroup = 1;
    for (const group of overlapGroups) {
      const found = appsStartingAtTimeSlot.some((app) =>
        group.some((groupApp) => groupApp.id === app.id)
      );
      if (found) {
        appointmentGroup = group;
        totalInGroup = group.length;
        break;
      }
    }
    const result = appsStartingAtTimeSlot.map((app) => {
      const position = appointmentGroup.findIndex(
        (groupApp) => groupApp.id === app.id
      );
      return {
        ...app,
        overlap: {
          position: position,
          total: totalInGroup,
        },
      };
    });
    console.log(
      `DayView - getAppointmentsForTime: ${result.length} RDV à rendre pour le créneau ${timeSlot}`
    );
    return result;
  };

  // Formatter la date pour l'en-tête
  const formatDayHeader = () => {
    const options = { weekday: "long", day: "numeric", month: "long" };
    const header = currentDate.toLocaleDateString("fr-FR", options);
    console.log(`DayView - formatDayHeader: ${header}`);
    return header;
  };

  // Log pour déboguer
  useEffect(() => {
    console.log("DayView - useEffect: Préparation des rendez-vous");
    console.log("DayView - Rendez-vous reçus:", appointments);

    // Vérifier le format des dates
    appointments.forEach((app) => {
      const normalizedDate = normalizeDateFormat(app.date);
      console.log(
        `DayView - Rendez-vous ${app.id} - date originale: ${app.date}, date normalisée: ${normalizedDate}, type: ${app.type}, couleur: ${app.color}`
      );
    });

    // Tester si des rendez-vous correspondent au jour affiché
    const formattedDate = formatDateForAttribute(currentDate);
    const dayAppointments = getDayAppointments();
    console.log(
      `DayView - Total des rendez-vous pour le jour (${formattedDate}): ${dayAppointments.length}`
    );
  }, [appointments, currentDate]);

  // Utiliser des valeurs par défaut pour timeStep si settings n'est pas complet
  const timeStep = settings?.timeStep || 30;
  console.log(
    `DayView - Pas de temps utilisé pour l'affichage: ${timeStep}min`
  );

  // Définir currentTimePosition
  const currentTimePosition = calculateTimeIndicatorPosition();
  console.log(
    `DayView - Position de l'indicateur d'heure actuelle: ${currentTimePosition}`
  );

  return (
    <div id="day-view" className="calendar-view active">
      <div className="week-container">
        {/* En-tête du jour */}
        <div className="week-header">
          <div className="time-header-cell"></div>
          <div
            className={`day-header-cell ${
              isToday(currentDate) ? "today" : ""
            } ${isClosedDay(currentDate) ? "closed-day" : ""}`}
          >
            {formatDayHeader()}
          </div>
        </div>

        {/* Grille avec défilement */}
        <div className="week-grid" ref={weekGridRef}>
          {/* Colonne des heures */}
          <div className="time-column">
            {timeSlots.map((time, index) => {
              // N'afficher que les heures complètes et les demi-heures pour plus de clarté
              const [_, minute] = time.split(":").map(Number);
              if (minute === 0 || (minute === 30 && timeStep <= 30)) {
                return (
                  <div className="time-cell" key={`time-${index}`}>
                    <span>{time}</span>
                  </div>
                );
              }
              return (
                <div className="time-cell empty" key={`time-${index}`}></div>
              );
            })}
          </div>

          {/* Colonne du jour */}
          <div
            className={`day-column ${isToday(currentDate) ? "today" : ""} ${
              isClosedDay(currentDate) ? "closed-day" : ""
            }`}
          >
            {timeSlots.map((timeSlot, timeIndex) => {
              // Debug: log pour chaque créneau
              const appsForSlot = getAppointmentsForTime(timeSlot);
              const hasApps = appsForSlot.length > 0;
              if (hasApps) {
                console.log(
                  `DayView - Rendu du créneau ${timeSlot} avec ${appsForSlot.length} RDV`
                );
              }

              return (
                <div
                  key={`slot-${timeIndex}`}
                  className={`time-cell
                    ${isSlotSelected(timeSlot) ? "selected" : ""}
                    ${
                      isSelecting && isSlotSelected(timeSlot) ? "selecting" : ""
                    }
                    ${dragOverInfo === timeSlot ? "drag-over" : ""}
                    ${isClosedTimeSlot(timeSlot) ? "closed-time" : ""}
                    ${hasApps ? "has-appointments" : ""}
                  `}
                  data-time={timeSlot}
                  data-has-appointments={hasApps ? "true" : "false"}
                  data-appointments-count={appsForSlot.length}
                  onMouseDown={(e) =>
                    !isClosedDay(currentDate) && handleMouseDown(e, timeSlot)
                  }
                  onMouseOver={() =>
                    !isClosedDay(currentDate) && handleMouseOver(timeSlot)
                  }
                  onMouseUp={handleMouseUp}
                  onDragOver={(e) =>
                    !isClosedDay(currentDate) && handleDragOver(e, timeSlot)
                  }
                  onDrop={(e) =>
                    !isClosedDay(currentDate) && handleDrop(e, timeSlot)
                  }
                >
                  {isClosedTimeSlot(timeSlot) && !isClosedDay(currentDate) && (
                    <div className="closed-indicator">Fermé</div>
                  )}

                  {appsForSlot.map((appointment) => {
                    console.log(
                      `DayView - RENDU DU RENDEZ-VOUS ${appointment.id} (${appointment.client_name}) pour le créneau ${timeSlot}`
                    );

                    const borderColor = appointment.color
                      ? adjustColor(appointment.color, -20)
                      : "#b7a382";

                    // Calcul de la largeur et de la position si le rendez-vous fait partie d'un groupe qui se chevauche
                    const hasOverlap =
                      appointment.overlap && appointment.overlap.total > 1;
                    // Réduire légèrement la largeur pour laisser un petit espace entre les rendez-vous
                    const widthPercent = hasOverlap
                      ? 100 / appointment.overlap.total - 1
                      : 95;
                    const width = `${widthPercent}%`;
                    const left = hasOverlap
                      ? `${
                          (appointment.overlap.position * 100) /
                          appointment.overlap.total
                        }%`
                      : "2.5%";

                    // Calcul de la hauteur en pixels
                    const heightInPixels = Math.max(
                      20,
                      (parseInt(appointment.duration) / timeStep) *
                        (60 / (60 / timeStep))
                    );
                    console.log(
                      `DayView - RDV ${appointment.id}: largeur=${width}, position=${left}, hauteur=${heightInPixels}px`
                    );

                    return (
                      <div
                        key={appointment.id}
                        className="appointment"
                        data-appointment-id={appointment.id}
                        data-time-slot={timeSlot}
                        data-client={appointment.client_name}
                        data-type={appointment.type}
                        data-height={heightInPixels}
                        style={{
                          position: "absolute !important",
                          display: "block !important",
                          visibility: "visible !important",
                          opacity: 1,
                          height: `${heightInPixels}px`,
                          minHeight: "20px", // Hauteur minimale pour être visible
                          backgroundColor: appointment.color || "#c6b291",
                          width: width,
                          left: left,
                          zIndex: hasOverlap
                            ? 10 + appointment.overlap.position
                            : 999,
                          borderLeft: `3px solid ${borderColor}`,
                          color: "white",
                          padding: "5px 8px",
                          borderRadius: "4px",
                          overflow: "hidden",
                          cursor: "pointer",
                          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                          boxSizing: "border-box",
                          whiteSpace: "nowrap",
                          top: 0,
                        }}
                        onClick={(e) => {
                          console.log(
                            `DayView - Clic sur le RDV ${appointment.id}`
                          );
                          e.stopPropagation();
                          onAppointmentClick(appointment.id);
                        }}
                        draggable={true}
                        onDragStart={(e) => handleDragStart(e, appointment)}
                        onDragEnd={handleDragEnd}
                      >
                        <div className="appointment-time">
                          {normalizeTime(appointment.start)}
                        </div>
                        <div className="appointment-client">
                          {appointment.client_name || "Client"}
                        </div>
                        <div className="appointment-type">
                          {appointment.type}
                        </div>
                      </div>
                    );
                  })}

                  {/* Indicateur de débogage pour les rendez-vous */}
                  {hasApps && (
                    <div
                      className="debug-appointment-indicator"
                      style={{
                        position: "absolute",
                        top: "2px",
                        right: "2px",
                        background: "red",
                        color: "white",
                        borderRadius: "50%",
                        width: "16px",
                        height: "16px",
                        fontSize: "12px",
                        textAlign: "center",
                        lineHeight: "16px",
                        zIndex: 9999,
                      }}
                    >
                      {appsForSlot.length}
                    </div>
                  )}
                </div>
              );
            })}

            {/* Indicateur d'heure actuelle */}
            {isToday(currentDate) && currentTimePosition && (
              <div
                className="current-time-indicator"
                style={{ top: currentTimePosition }}
              />
            )}

            {/* Indicateur de durée de sélection */}
            {isSelecting && selectionDuration && (
              <div className="selection-duration">{selectionDuration} min</div>
            )}

            {/* Compteur de rendez-vous pour débogage */}
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                background: "rgba(0,0,0,0.7)",
                color: "white",
                padding: "5px 10px",
                borderRadius: "5px",
                zIndex: 9999,
              }}
            >
              {cachedDayAppointments.length} rendez-vous aujourd'hui
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Fonction utilitaire pour ajuster la couleur (assombrir ou éclaircir)
function adjustColor(color, amount) {
  return (
    "#" +
    color.replace(/^#/, "").replace(/../g, (color) => {
      const colorNum = parseInt(color, 16);
      const newColorNum = Math.max(Math.min(colorNum + amount, 255), 0);
      return newColorNum.toString(16).padStart(2, "0");
    })
  );
}
